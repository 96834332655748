import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getLocalStorageItem, scrollToTopSmooth } from "../sdk/utils";
import { DEFAULT_LOCALE } from "../typescript/constants";
import { useTranslation } from "react-i18next";

const {
  REACT_APP_CONTENTSTACK_DEFAULT_LOCALE
} = process.env;

export const useLocaleRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  useEffect(() => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const firstSegment = pathSegments[0];
    // Check if the first path segment is a valid locale
    const isValidLocale = /^[a-z]{2}-[a-z]{2}$/.test(firstSegment);

    if (!isValidLocale) {
      const preferredLocale = getLocalStorageItem('preferred_locale');
    // Set locale based on priority: Preferred locale -> Domain locale -> Default locale
      const locale = preferredLocale ?? REACT_APP_CONTENTSTACK_DEFAULT_LOCALE ?? DEFAULT_LOCALE;
      if(locale !== i18n.language) i18n.changeLanguage(locale)
      const newPath = `/${locale}${location.pathname}${location.search ?? ''}`;
      navigate(newPath, { replace: true });
    }
    if (!location.hash) //prefer scroll to hash
      scrollToTopSmooth()
  }, [location]);
};

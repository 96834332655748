import React, { useState, CSSProperties, useEffect, useRef, useLayoutEffect } from "react";
import styles from "../styles/Header.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { HeaderRes, NavigationMenu } from "../typescript/response";
import MegaNav from "./mega-nav";
import LanguageSelector from "./language-selector";
import SearchBar from "./search-bar";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function Header({
  header,
  navMenu,
}: {
  header: HeaderRes;
  navMenu: NavigationMenu[];
}) {
    const { i18n } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [isMenuLevelTwoOpened, setIsMenuLevelTwoOpened] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

  // Handle locale change
  const handleLocaleChange = (newLocale: string) => {
    const currentPath = location.pathname.split("/").slice(2).join("/");
    navigate(`/${newLocale}/${currentPath}`);
    i18n.changeLanguage(newLocale);
    closeAllModal();
  };

  // Toggle the navigation menu state
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
    if (!isNavOpen) {
      setIsSearchOpen(false); // Close search if the navigation is opened
    }
  };

  // Toggle the search bar state
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setIsNavOpen(false); // Close navigation if the search is opened
    }
  };

  const closeAllModal = () => {
    setIsNavOpen(false);
    setIsSearchOpen(false);
  }

    const [isOpenMobileSideBar, setIsOpenMobileSideBar] = useState(false);

  useEffect(() => {
    const hide = () => (document.body.style.overflow = "hidden");
    const show = () => (document.body.style.overflow = "auto");

    const handleResize = () => {
      window.innerWidth >= 1200
        ? show()
        : isOpenMobileSideBar
          ? hide()
          : show();
    };

    if (isOpenMobileSideBar) {
      hide();
    } else if (window.innerWidth < 1200) {
      show();
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpenMobileSideBar]);

  const halfPill: CSSProperties = {
    backgroundColor: isHovered ? "transparent" : "var(--primary-500)",
    color: isHovered ? "var(--primary-500)" : "var(--white)",
    border: "1px solid var(--primary-500)",
    borderRadius: "var(--br-base) 0 0 var(--br-base)",
    boxSizing: "border-box",
    cursor: "pointer",
    transition:
      "background-color 300ms ease-out, color 300ms ease-out, box-shadow 300ms ease-out",
    textAlign: "center",
    textDecoration: "none",
    flex: "1",
    lineHeight: "20px",
    justifyContent: "center",
    padding: "3.5px var(--xs)",
    fontSize: "var(--small-size)",
    width: "102px",
    };

    const fullPill: CSSProperties = {
        backgroundColor: isHovered ? "transparent" : "var(--primary-500)",
        color: isHovered ? "var(--primary-500)" : "var(--white)",
        border: "1px solid var(--primary-500)",
        borderRadius: "var(--br-base) var(--br-base) var(--br-base)  var(--br-base)",
        boxSizing: "border-box",
        cursor: "pointer",
        transition:
            "background-color 300ms ease-out, color 300ms ease-out, box-shadow 300ms ease-out",
        textAlign: "center",
        textDecoration: "none",
        flex: "1",
        lineHeight: "20px",
        justifyContent: "center",
        padding: "3.5px var(--xs)",
        fontSize: "var(--small-size)",
        width: "102px",
    };

  const appendHeightParam = (url: string, height: number) => {
    try {
      const urlObj = new URL(url);
      urlObj.searchParams.set("height", height.toString());
      return urlObj.toString();
    } catch (error) {
      console.error("Invalid URL:", error);
      return url;
    }
    };

    const headerRef = useRef<HTMLDivElement>(null);
    const [headerHeight, setHeaderHeight] = useState<number>(0);

    const updateHeaderHeight = () => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current.offsetHeight);
        }
    };

    // Calculate height after initial render
    useLayoutEffect(() => {
        updateHeaderHeight();

        // Use MutationObserver to watch for changes in header content
        const observer = new MutationObserver(() => updateHeaderHeight());
        if (headerRef.current) {
            observer.observe(headerRef.current, {
                childList: true,
                subtree: true,
                attributes: true,
            });
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    // Update height on resize
    useEffect(() => {
        window.addEventListener("resize", updateHeaderHeight);

        return () => {
            window.removeEventListener("resize", updateHeaderHeight);
        };
    }, []);

    return (
        <>
            <Helmet>
                {header.metadata && header.metadata.site_title && (
                    <title>{header.metadata.site_title}</title>
                )}
                {header.metadata && header.metadata.fav_icon && (
                    <link
                        rel="icon"
                        href={header.metadata.fav_icon.url}
                        type="image/x-icon"
                    />
                )}
            </Helmet>
        <header ref={headerRef} className={styles.navWrap}>
            <div className={styles.container}>
                <div className={styles.logoDark}>

                    {isMenuLevelTwoOpened ? (
                            <a className={styles.backButton} onClick={() => { setIsMenuLevelTwoOpened(false); }} >
                                <svg style={{ marginRight: '6px' }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="np_back_1833621_000000 1">
                                        <path id="Vector" d="M5.82442 4.99455C6.00161 4.82439 6.00161 4.534 5.82442 4.36385C5.65427 4.18666 5.37091 4.18666 5.19372 4.36385L0.658623 8.89193C0.488467 9.06912 0.488467 9.35248 0.658623 9.52967L5.19372 14.0577C5.37091 14.2349 5.65427 14.2349 5.82442 14.0577C6.00161 13.8876 6.00161 13.5972 5.82442 13.427L2.06134 9.66397H16.0999C16.3481 9.66397 16.5464 9.45866 16.5464 9.21045C16.5464 8.96225 16.3481 8.76397 16.0999 8.76397H2.06134L5.82442 4.99386V4.99455Z" fill="#37353C" />
                                    </g>
                                </svg>
                                Back</a>
                        ) : (
                        <>
                        {header.logo ? (
                                <Link to={`/${i18n.language}`} title="Home" onClick={closeAllModal}>
                                    <img
                                        {...(header.logo.$?.url as {})}
                                        className="logo"
                                        loading="lazy"
                                        alt={header.logo.title}
                                        src={appendHeightParam(header.logo.url, 55)}
                                    />
                                </Link>
                            ) : (
                                <Skeleton width={200} />
                        )}
                        </>
                    )}

                </div>
                <MegaNav
                navMenu={navMenu}
                isMobile={false}
                isOpenMobileSideBar={isOpenMobileSideBar}
                handleCloseSideBar={setIsOpenMobileSideBar}
                isMenuLevelTwoOpened={isMenuLevelTwoOpened} // <-- Pass the state
                handleMenuLevelTwoOpened={setIsMenuLevelTwoOpened} // <-- Pass the setter function
                languages={header.languages}
                currentLocale={i18n.language}
                onLocaleChange={handleLocaleChange}
                locations={header.locations}
                request_quote={header.request_quote}
                contact_us={header.contact_us}
                />
                <div className={styles.sideNav}>
                <div className={styles.top}>
                    {header?.search_page?.[0]?.url && (
                    <SearchBar
                        search_page={header.search_page[0].url}
                        search_placeholder={header.search_placeholder}
                    />
                    )}
                    {header?.locations?.page_reference?.[0]?.url && (
                    <Link
                        to={`/${i18n.language}${header.locations.page_reference[0].url}`}
                        className={styles.locations}
                    >
                        {header.locations.label}
                    </Link>
                                )}
                    {header?.show_language_selector && (
                    <LanguageSelector
                    languages={header.languages}
                    currentLocale={i18n.language}
                    onLocaleChange={handleLocaleChange}
                                    />
                )}
                </div>
                <div className={styles.bottom}>
                    {header.request_quote?.page_reference[0]?.url && (
                    <Link
                        to={`/${i18n.language}${header.request_quote.page_reference[0].url}${header.request_quote.form_type ? `?form_type=${header.request_quote.form_type}` : ""}`}
                        className={styles.requestAQuote}
                    >
                        {header.request_quote.label ?? "Request a Quote"}
                    </Link>
                    )}
                    {header.contact_us?.page_reference[0]?.url && (
                    <Link
                        to={`/${i18n.language}${header.contact_us.page_reference[0].url}${header.contact_us.form_type ? `?form_type=${header.contact_us.form_type}` : ""}`}
                                    style={fullPill}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {header.contact_us.label ?? "Contact"}
                    </Link>
                    )}
                </div>
                </div>
                <div className={styles.hamburgerMenu}>
                {header?.search_page?.[0]?.url && (
                    <SearchBar
                    search_page={header.search_page[0].url}
                    isVisible={isSearchOpen}
                    search_placeholder={header.search_placeholder}
                    toggleVisibility={toggleSearch}
                    />
                )}
                {isNavOpen ? (
                    <img
                    onClick={toggleNav}
                    className={styles.closeMenuIcon}
                    loading="lazy"
                    alt="Menu"
                    src="/closex.svg"
                    />
                ) : (
                    <img
                    onClick={toggleNav}
                    className={styles.hamburgerMenuIcon}
                    loading="lazy"
                    alt="Menu"
                    src="/hamburger.svg"
                    />
                )}
                <MegaNav
                    navMenu={navMenu}
                    isMobile={true}
                    isOpenMobileSideBar={isNavOpen}
                    handleCloseSideBar={() => setIsNavOpen(false)}
                    isMenuLevelTwoOpened={isMenuLevelTwoOpened} // <-- Pass the state
                    handleMenuLevelTwoOpened={setIsMenuLevelTwoOpened} // <-- Pass the setter function
                    showLanguageSelector={header.show_language_selector}
                    languages={header.languages}
                    currentLocale={i18n.language}
                    onLocaleChange={handleLocaleChange}
                    locations={header.locations}
                    request_quote={header.request_quote}
                    contact_us={header.contact_us}
                />
                </div>
            </div>
        </header>
        <div style={{ paddingTop: headerHeight }}></div>
      </>
  );
}

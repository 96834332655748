import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RenderComponents from "../components/render-components";
import { getPageRes } from "../helper";
import Skeleton from "react-loading-skeleton";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { EntryProps } from "../typescript/components";
import { Page } from "../typescript/pages";
import styles from "../styles/Index.module.css";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { handleScrollToHash } from "../helper/utils";

export default function Home({ entry }: { entry: ({ page }: EntryProps) => void; }) {
  const lpTs = useLivePreviewCtx();
  const [getEntries, setEntries] = useState({} as Page);
  const [error, setError] = useState(false);
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  let entryUrl = location.pathname.startsWith(`/${i18n.language}`)
    ? location.pathname.replace(`/${i18n.language}`, "")
    : location.pathname;

  if (entryUrl === "") entryUrl = "/";

  async function fetchData() {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const firstSegment = pathSegments[0];
    const isValidLocale = /^[a-z]{2}-[a-z]{2}$/.test(firstSegment);
    if (!isValidLocale) return;
    try {
      const result = await getPageRes(entryUrl);
      if (!result) {
        navigate(`/${i18n.language}/404`);
        setError(true);
      } else {
        setEntries(result);
        entry({ page: [result] });
      }
    } catch (error) {
      setError(true);
      console.error(error);
    }
    }

    
    useEffect(() => {
        if (location.hash) {
            const hash = location.hash.substring(1);
            const element = document.getElementById(hash);

            if (!element) {
                // Observe DOM changes until the element is found
                const observer = new MutationObserver(() => {
                    const target = document.getElementById(hash);
                    if (target) {
                        handleScrollToHash(hash);
                        observer.disconnect(); // Stop observing once the target is found
                    }
                });

                observer.observe(document.body, {
                    childList: true,
                    subtree: true,
                });

                return () => observer.disconnect();
            } else {
                handleScrollToHash(hash);
            }
        }
    }, [location.hash]);

  const meta_title = getEntries?.seo?.meta_title;
  const meta_description = getEntries?.seo?.meta_description;
  const keywords = getEntries?.seo?.keywords;
  const darkBackground = getEntries.dark_background ? styles.darkBackground : "";

  useEffect(() => {
    fetchData();
  }, [entryUrl, lpTs, error, i18n.language, location.pathname]);
    const canonicalUrl = `${window.location.origin}${window.location.pathname}`;

  return Object.keys(getEntries).length ? (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_title && <meta property="og:title" content={meta_title} />}
        {meta_description && <meta name="description" content={meta_description} />}
        {meta_description && <meta property="og:description" content="This is a description of my page." />}
        {keywords && <meta name="keywords" content={keywords} />}
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className={`${darkBackground}`}>
        <RenderComponents
          pageComponents={getEntries?.page_components}
          contentTypeUid="page"
          entryUid={getEntries?.uid}
          locale={getEntries?.locale}
          taxonomies={getEntries?.taxonomies}
        />
      </div>
    </>
  ) : (
    <Skeleton count={5} height={400} />
  );
}
import React from "react";
import { HeroTitle as HeroTitlePros } from "../typescript/components";
import styles from "../styles/HeroTitle.module.css";
import { useLocation } from "react-router";

export default function HeroTitle({
  hero_title,
}: {
  hero_title: HeroTitlePros;
}) {
  const alignTextClass =
    hero_title?.align_text === "Left"
      ? styles.textAlignLeft
      : styles.textAlignCenter;

  const bottomBorder = hero_title.bottom_border ? styles.bottomBorder : "";

  const location = useLocation();
  const path = location.pathname;

  // Split the path into segments, skipping the language segment (usually the first one)
  const segments = path
    .split("/")
    .filter((segment) => segment)
    .slice(1);

  const breadcrumbs = [
    { name: "Home", path: "/" },
    ...segments.map((segment, index) => {
      const segmentPath = `/${segments.slice(0, index + 1).join("/")}`;
      return {
        name:
          segment.charAt(0).toUpperCase() + segment.slice(1).replace(/-/g, " "),
        path: segmentPath,
      };
    }),
  ];

  if (hero_title && breadcrumbs.length > 0) {
    breadcrumbs[breadcrumbs.length - 1].name = hero_title.title;
    }

    const toProperCase = (str: string) =>
        str
            .replace(/-/g, " ")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ");

    const pathnames = location.pathname.split("/").filter((x) => x);
    const filteredPathnames = pathnames.slice(1); // Remove locale from the breadcrumb

  return (
    <div
      className={`${styles.containerHeroTitle} ${alignTextClass} ${bottomBorder}`}
    >
      {breadcrumbs && hero_title.show_breadcrumb && (
        <div className={styles.breadcrumb}>
                  <span>
                      <a href="/" className={styles.breadcrumbLink}>
                          Home
                      </a>
                  </span>
                  {filteredPathnames.map((value, index) => {
                      const pageName = value.toLowerCase();
                      const specialPages = ["article", "case-study", "webinar", "white-paper", "video"];
                      const isSpecialPage = specialPages.includes(pageName);
                      const to = isSpecialPage
                          ? `/resources?type=${pageName}`
                          : `/${filteredPathnames.slice(0, index + 1).join("/")}`;
                      const isLast = index === filteredPathnames.length - 1;

                      return (
                          <React.Fragment key={to}>
                              <span>&nbsp;/&nbsp;</span>
                              {isLast ? (
                                  <span key={to} className={styles.currentBreadcrumb} aria-current="page">
                                      {toProperCase(value)}
                                  </span>
                              ) : (
                                  <span key={index}>
                                      <a href={to} className={styles.breadcrumbLink}>
                                          {toProperCase(value)}
                                      </a>
                                  </span>
                              )}
                          </React.Fragment>
                      );
                  })}
        </div>
      )}
      <div className={styles.eyebrow}>{hero_title?.eyebrow}</div>
      {hero_title?.header_tag_type === "H3" ? (
        <h3 className={styles.title}>{hero_title?.title}</h3>
      ) : (
        <h1 className={styles.title}>{hero_title?.title}</h1>
      )}
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: hero_title.content }}
      ></div>
    </div>
  );
}

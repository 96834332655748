import { PageTitleHeader } from "../typescript/components";
import React, { useState, useEffect, useMemo, useRef } from "react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/MetalSelector.module.css";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper/modules";
import Box from "@mui/material/Box";
import PageTitle from "../components/page-title";
import { getMetalSelectorOverviewRes } from "../helper";
import { MetalSelectorOverviewPage } from "../typescript/pages";
import { Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { CustomizedSwitch } from "../components/customized-mui/switch";
import CustomizedRatio from "../components/customized-mui/radio";
import CustomizedSlider from "../components/customized-mui/slider";
import { StyledTableCell, StyledTableRow } from "../components/customized-mui/table";
import { CustomizedTab, CustomizedTabs } from "../components/customized-mui/tab";
import CustomizedCheckbox from "../components/customized-mui/checkbox";
import Button from "../components/button";
import { Helmet } from "react-helmet-async";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
interface TabPanelProps {
    index: number;
    value: number;
}
interface SwiperRatioProps {
    name: string;
    id: string;
}

interface Filter {
    dieCastingMetal: string | null;
    region: string | null;
    minTensile: number;
    minYieldStrength: number;
    minThermalConductivity: number;
    minElectricalConductivity: number;
    minShearStrength: number;
    minElongation: number;
    sortBy: string;
    sortType: string;
}

interface TableData {
    mechanical: string[][],
    physical: string[][],
    composition: string[][][],
}
const TABS = {
    MECHANICAL: "Mechanical Properties",
    PHYSICAL: "Physical Properties",
    COMPOSITION: "Composition",
};
const compositionHeaders = ["Aluminum", "Copper", "Magnesium", "Iron (max)", "Tin (max)", "Nickel (max)", "Zinc", "Manganese", "Silicon", "Other-Metallic", "RoHS Compliant"];

const dataAndMeasurementUnitMapping = [
    {
        names: [
            {
                fieldName: "Tensile Strength",
                tab: TABS.MECHANICAL,
                index: 2,
            },
            {
                fieldName: "Yield Strength (0.2%)",
                tab: TABS.MECHANICAL,
                index: 3,
            },
            {
                fieldName: "Shear Strength",
                tab: TABS.MECHANICAL,
                index: 5,
            },
        ],
        unitImperial: "PSI x 10³",
        unitMetric: "MPa",
        sortable: true,
        icon: true,
        convert: (value: string) => Math.round((parseFloat(value) / 6.89476)).toFixed(0).toString(), // MPa to PSI
        reConvert: (value: string) => Math.round((parseFloat(value) * 6.89476)).toFixed(0).toString(), // PSI to MPa
    },
    {
        names: [
            {
                fieldName: "Compare Alloy",
                tab: TABS.MECHANICAL,
                index: 0,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: true,
        icon: false,
        convert: null,
        reConvert: null,
    },
    {
        names: [
            {
                fieldName: "Elongation",
                tab: TABS.MECHANICAL,
                index: 1,
            },
        ],
        unitImperial: "% in 50mm",
        unitMetric: "% in 50mm",
        sortable: true,
        icon: true,
        convert: null,
        reConvert: null,
    },

    {
        names: [
            {
                fieldName: "Impact Strength",
                tab: TABS.MECHANICAL,
                index: 4,
            },
        ],
        unitImperial: "ft lb",
        unitMetric: "J",
        sortable: true,
        icon: true,
        convert: (value: string) => Math.round((parseFloat(value) * 0.737562)).toFixed(0).toString(), // J to ft·lb
        reConvert: (value: string) => Math.round((parseFloat(value) / 0.737562)).toFixed(0).toString(), // ft·lb to J
    },
    {
        names: [
            {
                fieldName: "Hardness Brinell",
                tab: TABS.MECHANICAL,
                index: 6,
            },
        ],
        unitImperial: "Brinell (HB)",
        sortable: true,
        icon: true,
        convert: null,
        reConvert: null,
    },

    {
        names: [
            {
                fieldName: "Process",
                tab: TABS.MECHANICAL,
                index: 7,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: true,
        icon: false,
        convert: null,
        reConvert: null,
    },
    {
        names: [
            {
                fieldName: "Compare Alloy",
                tab: TABS.PHYSICAL,
                index: 0,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: true,
        icon: false,
        convert: null,
        reConvert: null,
    },
    {
        names: [
            {
                fieldName: "Compare Alloy",
                tab: TABS.COMPOSITION,
                index: 0,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: true,
        icon: false,
        convert: null,
        reConvert: null,
    },
    {
        names: [
            {
                fieldName: "Density",
                tab: TABS.PHYSICAL,
                index: 1,
            },
        ],
        unitImperial: "lb/in³",
        unitMetric: "g/cm³",
        sortable: true,
        icon: true,
        convert: (value: string) => Math.round(parseFloat(value) * 0.0361273).toFixed(0).toString(), // g/cm³ to lb/in³
        reConvert: (value: string) => Math.round(parseFloat(value) / 0.0361273).toFixed(0).toString(), // lb/in³ to g/cm³
    },
    {
        names: [
            {
                fieldName: "Melting Point (Average +/- 50)",
                tab: TABS.PHYSICAL,
                index: 2,
            },
        ],
        unitImperial: "°F",
        unitMetric: "°C",
        sortable: true,
        icon: true,
        convert: (value: string) => Math.round((parseFloat(value) * 9) / 5 + 32).toFixed(0).toString(), // °C to °F
        reConvert: (value: string) => Math.round((parseFloat(value) - 32) * 5 / 9).toFixed(0).toString(), // °F to °C
    },
    {
        names: [
            {
                fieldName: "Thermal Conductivity",
                tab: TABS.PHYSICAL,
                index: 3,
            },
        ],
        unitImperial: "BTU/h∙ft∙°F",
        unitMetric: "W/m K",
        sortable: true,
        icon: true,
        convert: (value: string) => (Math.round(parseFloat(value) * 0.577 * 10) / 10).toString(), // W / m K to BTU/h∙ft∙°F
        reConvert: (value: string) => (Math.round(parseFloat(value) / 0.577 * 10) / 10).toString(), // BTU/h∙ft∙°F to W / m K
    },
    {
        names: [
            {
                fieldName: "Coefficient of Thermal Expansion",
                tab: TABS.PHYSICAL,
                index: 4,
            },
        ],
        unitImperial: "µin/in°F",
        unitMetric: "µm/m°K",
        sortable: true,
        icon: true,
        convert: (value: string) => (Math.round(parseFloat(value) * 0.000021973 * 10) / 10).toString(), // µm/m°K to µin/in°F
        reConvert: (value: string) => (Math.round(parseFloat(value) / 0.000021973 * 10) / 10).toString(), // µin/in°F to µm/m°K 
    },
    {
        names: [
            {
                fieldName: "Electrical Conductivity",
                tab: TABS.PHYSICAL,
                index: 5,
            },
        ],
        unitImperial: "%IACS",
        unitMetric: "%IACS",
        sortable: true,
        icon: true,
        convert: null,
        reConvert: null,
    },
    {
        names: [
            {
                fieldName: "Process",
                tab: TABS.PHYSICAL,
                index: 6,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: true,
        icon: false,
        convert: null,
        reConvert: null,
    },
    ...compositionHeaders.map((composition: string, i: number) => ({
        names: [
            {
                fieldName: composition,
                tab: TABS.COMPOSITION,
                index: i + 1,
            },
        ],
        unitImperial: null,
        unitMetric: null,
        sortable: false,
        icon: false,
        convert: null,
        reConvert: null,
    })),
];

const a11yProps = (index: number) => {
    return {
        id: `full-width-tab-${index}`,
    };
};

function getCommonAlloys(arr1: string[], arr2: string[], arr3: string[]) {
    const nonEmptyArrays = [arr1, arr2, arr3].filter(arr => arr.length > 0);
    if (nonEmptyArrays.length === 0) {
      return [];
    }
    return nonEmptyArrays.reduce((commonElements, currentArray) => {
      return commonElements.filter(element => currentArray.includes(element));
    }, nonEmptyArrays[0]);
  }

const dataAndMeasurementUnit = dataAndMeasurementUnitMapping
    .map((unit) => unit.names.map((name) => ({ ...unit, ...name })))
    .flat()
    .sort((a, b) => a.index - b.index);

export default function MetalSelector({ url }: { url: string }) {
    const defaultFilter = {
        dieCastingMetal: null,
        region: null,
        minTensile: 0,
        minElectricalConductivity: 0,
        minYieldStrength: 0,
        minThermalConductivity: 0,
        minShearStrength: 0,
        minElongation: 0,
        sortBy: "",
        sortType: "asc",
    };
    const [entries, setEntries] = useState({} as MetalSelectorOverviewPage);
    const [measurementUnit, setMeasurementUnit] = React.useState<"IMPERIAL" | "METRIC">("IMPERIAL");
    const [tab, setTab] = React.useState<number>(0);
    const [rows, setRows] = useState<TableData>({
        mechanical: [],
        physical: [],
        composition: [],
    });
    const [selectedRows, setSelectedRows] = useState([]);
    const [compare, setCompare] = useState(false);
    const [filterTempValue, setFilterTempValue] = useState(defaultFilter);
    const handleTempFilterChange = (newValue: number | number[], field: string) => {
        setFilterTempValue({ ...filterTempValue, [field]: newValue as number });
    };
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };
    const { i18n } = useTranslation();
    const [data, setData] = useState({});
    const [filter, setFilter] = useState<Filter>(defaultFilter);
    const [switchChecked] = useState(false);
    const [print, setPrint] = useState(false);
    const pageTitleData = {
        title: entries?.header_title,
        description: entries?.header_description,
        hideBorder: true,
        titleFontSize: 49,
    } as PageTitleHeader;

    const tableRef = useRef<HTMLDivElement>(null);
    const handleClosePrintData = async () => {
        setPrint(false);
    };
    const reactToPrintFn = useReactToPrint({
        contentRef: tableRef,
        pageStyle: `
            body {
                -webkit-print-color-adjust: exact;
            }
        `,
        onAfterPrint: handleClosePrintData,
        onPrintError(errorLocation, error) {
            console.error("Error when printing ", error);
            handleClosePrintData();
        },
    });
    const handleRenderPrintData = async () => {
        setPrint(true);
        // make sure the print data is rendered
        await new Promise((resolve) => setTimeout(resolve, 0));
        reactToPrintFn();
    };

    const getSlider = (start: string, middle: string, end: string, title: string, max: number, field: keyof Filter) => (
        <div className={styles.sliderContainer} key={title}>
            <p className={styles.filterName}>{title}</p>
            <div className={styles.sliderWrapper}>
                <CustomizedSlider
                    value={Number(filterTempValue?.[field] ?? 0)}
                    onChangeCommitted={(_, newValue) => setFilter((prev) => ({ ...prev, [field]: Number(newValue || 0) }))} // only render table after commit change
                    onChange={(_, newValue) => handleTempFilterChange(newValue, field)}
                    name={title}
                    max={max}
                    marks={[
                        {
                            value: max / 2,
                            label: middle,
                        },
                    ]}
                />
            </div>
            <div className={styles.sliderMark}>
                <p>{start}</p>
                <p>{middle}</p>
                <p>{end}</p>
            </div>
        </div>
    );

    const isFieldWithIcon = (name: string) => {
        if (!name) return;
        return ["Alloy", "Tensile Strength", "Yield Strength (0.2%)", "Impact Strength", "Hardness", "Alloy", "Density", "Melting Point (Average +/- 50)", "Thermal Conductivity", "Coefficient of Thermal Expansion", "Electrical Conductivity", "Die Casting Process"].includes(name)
    }

    const ratiosDataMetals = [
        { id: "1", name: "Aluminum" },
        { id: "3", name: "Magnesium" },
        { id: "2", name: "Zinc" },
    ];

    const sliderData = useMemo(() => {
        const isUS = measurementUnit === "IMPERIAL";
        const mpaData = dataAndMeasurementUnitMapping.find((d) => d.unitMetric === "MPa");
        const wmkData = dataAndMeasurementUnitMapping.find((d) => d.unitMetric === "MPa");
        const mpa = isUS ? mpaData?.unitImperial : mpaData?.unitMetric;
        const wmk = isUS ? wmkData?.unitImperial : wmkData?.unitMetric;
        const tensileMax = isUS ? 60 : 400;
        const yieldMax = isUS ? 60 : 400;
        const thermalMax = isUS ? 100 : 170;
        const shearMax = isUS ? 50 : 325;
        const sliderList = []

        if (rows.mechanical?.[0]?.find(m => m === 'Tensile Strength')) 
            sliderList.push(getSlider(`0 ${mpa}`, `>${filterTempValue.minTensile} ${mpa}`, `${Number(tensileMax)} ${mpa}`, `Tensile Strength (${mpa})`, Number(tensileMax), "minTensile"))

        if (rows.mechanical?.[0]?.find(m => m === 'Yield Strength (0.2%)')) 
            sliderList.push(getSlider(`0 ${mpa}`, `>${filterTempValue.minYieldStrength} ${mpa}`, `${Number(yieldMax)} ${mpa}`, `Yield Strength (0.2%) (${mpa})`, Number(yieldMax), "minYieldStrength"))

        if (rows.physical?.[0]?.find(m => m === 'Thermal Conductivity')) 
            sliderList.push(getSlider(`0 ${wmk}`, `>${filterTempValue.minThermalConductivity} ${wmk}`, `${Number(thermalMax)} ${wmk}`, `Thermal Conductivity (${wmk})`, Number(thermalMax), "minThermalConductivity"))
       
        if (rows.mechanical?.[0]?.find(m => m === 'Shear Strength')) 
            sliderList.push(getSlider(`0 ${mpa}`, `>${filterTempValue.minShearStrength} ${mpa}`, `${Number(shearMax)} ${mpa}`, `Shear Strength (${mpa})`, Number(shearMax), "minShearStrength"))
        
        if (rows.physical?.[0]?.find(m => m === 'Electrical Conductivity')) 
            sliderList.push(getSlider("0% IACS", `>${filterTempValue.minElectricalConductivity}% IACS`, "40% IACS", "Electrical Conductivity (% IACS)", 40, "minElectricalConductivity"))
        
        if (rows.mechanical?.[0]?.find(m => m === 'Elongation')) 
            getSlider("0% in 50mm", `>${filterTempValue.minElongation}% in 50mm`, "15 % in 50mm", "Elongation (% in 50mm)", 50, "minElongation")
        
        
        return sliderList;
    }, [filterTempValue, measurementUnit, rows]);
    const meta_title = entries?.seo?.meta_title;
    const meta_description = entries?.seo?.meta_description;
    const keywords = entries?.seo?.keywords;
    const slidesPerView = 2.5;

    const filterData = (rowsData: TableData) => {
        const data: TableData = JSON.parse(JSON.stringify(rowsData))
        let physicalData = data.physical?.length > 0 ? data.physical : null
        let mechanicalData = data.mechanical?.length > 0 ? data.mechanical : null
        let compositionData = data.composition
        if (measurementUnit === "METRIC") {
            const mappingConvert = (p: string) => {
                if (!(p?.trim()) || p === '-') return null
                const data = dataAndMeasurementUnit.find(measure => measure.unitImperial === p.trim())
                return {
                    convert: data?.reConvert,
                    name: data?.unitMetric || data?.unitImperial || ''

                }
            }

            const convertsMechanical = data?.mechanical[1]?.map(mappingConvert)
            const convertsPhysical = data?.physical[1]?.map(mappingConvert)
            const convertRows = (data: string[], index: number, func: typeof convertsMechanical) => {
                // Ignore table header
                if (index < 1) return data
                return data.map((element, i) => {
                    if (!element || (typeof element === 'string' && (!element.trim() || element.trim() === '-'))) return element;
                    const convertFunc = func[i]?.convert
                    if (index === 1) {
                        return func[i]?.name || element
                    }
                    if (!convertFunc) return element

                    if (typeof element !== 'string') {
                        return convertFunc(element)
                    }
                    return element?.split('-')?.map(e => convertFunc(e))?.join('-');
                })
            }
            if (mechanicalData)
                mechanicalData = mechanicalData.map((data, i) => convertRows(data, i, convertsMechanical))

            if (physicalData)
                physicalData = physicalData.map((data, i) => convertRows(data, i, convertsPhysical))
        }

        const filterByFieldCompare = (data: string[][], fieldName: number | string, minValue: number | string) => {
            const fieldIndex = data[0].findIndex((d: string) => String(d) === fieldName);
            if (fieldIndex === -1) return data
            return data.filter((d, index) => {
                if (index < 2) return true;
                const value = parseFloat(d?.[fieldIndex]);
                const parsedMinValue = typeof minValue === 'string' ? parseFloat(minValue) : minValue;
                return !isNaN(value) && value >= parsedMinValue;
            });
        };

        const filterByFieldEqual = (data: string[][], fieldName: number | string, value: number | string) => {
            const fieldIndex = data[0].findIndex((d: string) => String(d) === fieldName);
            if (fieldIndex === -1) return data
            return data.filter((d, index) => {
                if (index < 2) return true;
                const compareValue = d?.[fieldIndex];
                return String(compareValue) === String(value);
            });
        };

        const applyFilter = (data: string[][]) => {

            const filterFieldsSlider: { key: keyof Filter, field: string }[] = [
                { key: 'minTensile', field: 'Tensile Strength' },
                { key: 'minYieldStrength', field: 'Yield Strength (0.2%)' },
                { key: 'minThermalConductivity', field: 'Thermal Conductivity' },
                { key: 'minShearStrength', field: 'Shear Strength' },
                { key: 'minElectricalConductivity', field: 'Electrical Conductivity' },
                { key: 'minElongation', field: 'Elongation' },
            ];

            const filterFieldsRadio: { key: keyof Filter, field: string }[] = [
                { key: 'dieCastingMetal', field: 'Material' },
            ];

            filterFieldsSlider.forEach(({ key, field }) => {
                const value = filter[key];
                if (value !== null && value !== undefined && filter[key]) {
                    data = filterByFieldCompare(data, field, value);
                }
            });
            filterFieldsRadio.forEach(({ key, field }) => {
                const value = filter[key];
                if (value !== null && value !== undefined && filter[key]) {
                    data = filterByFieldEqual(data, field, value);
                }
            });

            return data;
        };


        // Filter by field
        if (mechanicalData) mechanicalData = applyFilter(mechanicalData);
        if (physicalData) physicalData = applyFilter(physicalData);


        // Filter both datasets based on common alloys
        if (JSON.stringify(filter) !== JSON.stringify(defaultFilter)) {
            const mechanicalIndex = (mechanicalData && mechanicalData?.length > 0) ? mechanicalData[0].findIndex((d: string) => d === 'Alloy') : -1
            const physicalIndex = (physicalData && physicalData?.length > 0) ? physicalData[0].findIndex((d: string) => d === 'Alloy') : -1
            const mechanicalAlloys = (mechanicalData && mechanicalData?.length > 0 && mechanicalIndex !== -1) ? mechanicalData.map(data => String(data[mechanicalIndex])) : []
            const physicalAlloys = (physicalData && physicalData?.length > 0 && physicalIndex !== -1) ? physicalData.map(data => String(data[physicalIndex])) : []
            const compositionAlloys: string[] = [];
            compositionData?.forEach((composition) => {
                const alloyIndex = composition[0].findIndex((d: string) => d === '%')
                composition.slice(1).forEach(c => compositionAlloys.push(String(c[alloyIndex])))
            })
            const commonAlloys = getCommonAlloys(mechanicalAlloys, physicalAlloys, compositionAlloys)
            if (mechanicalData && mechanicalData?.flat().length > 0) {
                mechanicalData = [...mechanicalData.slice(0,2), ...mechanicalData.slice(2).filter(m => commonAlloys.includes(String(m[mechanicalIndex])))]
            }

            if (physicalData && physicalData?.flat().length > 0)
                physicalData = [...physicalData.slice(0,2), ...physicalData.slice(2).filter(p => commonAlloys.includes(String(p[physicalIndex])))]

            if (compositionData && compositionData?.flat(2).length > 0)
                compositionData = compositionData?.map((compositions) => {
                    const compositionIndex = compositions[0].findIndex((d: string) => d === '%')
                    compositions = [...compositions.slice(0, 1), ...compositions.slice(1).filter(p => {
                        return commonAlloys.includes(String(p[compositionIndex])
                        )
                    })]
                    return compositions
                }).filter(c => c.length > 0)
            
        }
       
        const sortRows = (data: string[][]) => {
            const fieldIndex = data[0].findIndex((d: string) => String(d) === filter.sortBy);
            if (fieldIndex === -1) return data;
            const headerRows = data.slice(0, 2);
            const sortedData = data.slice(2).sort((a, b) => {
                const valA = a[fieldIndex];
                const valB = b[fieldIndex];
                const numA = parseFloat(valA as string);
                const numB = parseFloat(valB as string);
                if (!isNaN(numA) && !isNaN(numB)) {
                    return filter.sortType === 'asc' ? numA - numB : numB - numA;
                } else {
                    const strA = String(valA).toLowerCase();
                    const strB = String(valB).toLowerCase();
                    return filter.sortType === 'asc' ? strA.localeCompare(strB) : strB.localeCompare(strA);
                }
            });

            return [...headerRows, ...sortedData];
        };


        if (filter.sortBy) {
            if (mechanicalData) {
                mechanicalData = sortRows(mechanicalData);
            }

            if (physicalData) {
                physicalData = sortRows(physicalData);
            }
        }

        setRows({
            mechanical: mechanicalData || [],
            physical: physicalData || [],
            composition: compositionData || [],
        });
    };

    useEffect(() => {
        if (JSON.stringify(data) === '{}') return;
        filterData(data as TableData);
    }, [filter, measurementUnit]);

    async function fetchData() {
        try {
            const result = await getMetalSelectorOverviewRes(url);
            setEntries({ ...result });
            const response = await fetch(result.data.url);
            const data = await response.arrayBuffer();
            const workbook = XLSX.read(data, { type: "array" });
            const rows: TableData = {
                mechanical: [[]],
                physical: [[]],
                composition: [[[]]],
            }
            let processData: string[][] = []
            if (workbook.Sheets["Alloy Process"])
                processData = XLSX.utils.sheet_to_json(workbook.Sheets["Alloy Process"], { header: 1, defval: "" });

            const trimRow = (row: string[]): string[] => {
                return row.map(cell => typeof cell === 'string' ? cell.trim() : cell);
            };
            Object.values(workbook.Sheets).forEach((w, i) => {
                const rawData = XLSX.utils.sheet_to_json(w, { header: 1, defval: "" }) as string[][];
                if (workbook.SheetNames[i] === 'Composition') {
                    const isEmptyRow = (row: string[]) => row.every(cell => !cell || cell === "");
                    const trimEmptyColumns = (row: string[]) => {
                        while (row.length > 0 && (row[row.length - 1] === "" || row[row.length - 1] === null)) {
                            row.pop();  // Remove empty cells from the end of the row
                        }
                        return row;
                    };
                    const trimmedRows = rawData.map(trimRow)
                    const tables: string[][][] = [];
                    let currentTable: string[][] = [];

                    for (let i = 0; i < trimmedRows.length; i++) {
                        const row = trimmedRows[i];

                        if (isEmptyRow(row)) {
                            if (currentTable.length > 0) {
                                tables.push(currentTable);
                                currentTable = [];
                            }
                        } else {
                            const trimmedRow = trimEmptyColumns(row);
                            currentTable.push(trimmedRow);
                        }
                    }
                    if (currentTable.length > 0) {
                        tables.push(currentTable);
                    }
                    rows.composition = tables
                }
                if (workbook.SheetNames[i] === 'Mechanical Properties') {
                    const alloyField = rawData[0].findIndex((d: string) => d?.trim() === 'Alloy')
                    const mappingProcess = (alloy: string) => {
                        return processData?.find(process => process[0] === alloy?.trim())?.[1] ?? ''
                    }

                    if (workbook.Sheets["Alloy Process"]) {
                        rawData[0].push("Die Casting Process")
                    }
                    const mappedData: string[][] = [rawData[0]];
                    rawData.slice(1).forEach((data: string[]) => {
                        const alloys = data?.[alloyField]?.split(',')
                        alloys.forEach(alloy => {
                            const newData: string[] = JSON.parse(JSON.stringify(data))
                            newData[alloyField] = alloy?.trim()
                            if (processData.length > 0)
                                newData.push(alloy ? mappingProcess(String(alloy)) : '')
                            mappedData.push(newData)
                        })
                    })
                    rows.mechanical = mappedData.map(trimRow);
                }
                if (workbook.SheetNames[i] === 'Physical Properties') {
                    const alloyField = rawData[0].findIndex((d: string) => d?.trim() === 'Alloy')
                    const mappingProcess = (alloy: string) => {
                        return processData?.find(process => process[0] === alloy?.trim())?.[1] ?? ''
                    }
                    if (workbook.Sheets["Alloy Process"]) {
                        rawData[0].push("Die Casting Process")
                    }
                    const mappedData: string[][] = [rawData[0]];
                    rawData.slice(1).forEach((data: string[]) => {
                        const alloy = data?.[alloyField]
                        if (processData.length > 0) {
                            data.push(alloy ? mappingProcess(String(alloy)) : '')
                        }

                        mappedData.push(data)
                    })
                    rows.physical = mappedData.map(trimRow);
                }
            })
            setData(rows as TableData);
            filterData(rows);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [i18n]);

    const activeSwitchText = {
        color: "var(--charcoal-500)",
        fontWeight: 700,
    };
    const inactiveSwitchText = {
        color: "#bfbebe",
        fontWeight: 400,
    };

    const getSwiperRatio = (options: SwiperRatioProps[], field: keyof Filter, title: string, alt: string) => (
        <div className={styles.radioContainer}>
            <div className={styles.radioTitleWrapper}>
                <p className={styles.filterName}>{title}</p>
                <p className={styles.filterAlt}>{alt}</p>
            </div>
            <Swiper
                spaceBetween={0}
                className={`${styles.ratioSwiper} metal-selector-swiper`}
                slidesPerView={slidesPerView}
                loop={false}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
            >
                {options?.map((slide, index) => (
                    <SwiperSlide key={index} className={styles.slide}>
                        <div className={styles.radioWrapper}>
                            <CustomizedRatio
                                value={slide.name}
                                name={slide.name}
                                checked={filter[field] === slide.name}
                                onChange={(e) => {
                                    setFilter({ ...filter, [field]: e.target.value });
                                }}
                            />
                            <p className={styles.radioTitle}>{slide.name}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );

    const tableData = (rows: (string | number)[][], fullHeight = false, currentTab = tab) => {
        return (
            <div className={styles.table}>
                <Box sx={{ overflow: "auto", paddingRight: "1px" }}>
                    <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                        <TableContainer sx={{ maxHeight: fullHeight ? "auto" : 615 }}>
                            <Table stickyHeader>
                                <TableHead
                                    key={1}
                                    sx={{
                                        position: "sticky",
                                        top: 0,
                                        zIndex: 1,
                                    }}
                                >
                                    <TableRow>
                                        {currentTab !== 2 && <StyledTableCell></StyledTableCell>}
                                        {rows[0]?.map((header: any, i) => (
                                            <>
                                                <StyledTableCell
                                                    sx={{ cursor: "pointer" }}
                                                    isCheckbox={currentTab !== 2}
                                                    key={`${header}${i}`}
                                                    onClick={() => {
                                                        setFilter({ ...filter, sortBy: header, sortType: filter.sortType === "asc" ? "desc" : "asc" });
                                                    }}
                                                >
                                                    {header}
                                                    {isFieldWithIcon(header) && <img className={styles.sortIcon} loading="lazy" src="/sort.svg" />}
                                                </StyledTableCell>
                                            </>
                                        ))}
                                    </TableRow>
                                    {currentTab !== 2 && (
                                        <TableRow>
                                            <StyledTableCell isCheckbox={true} variant="footer" style={{ top: "20px" }}></StyledTableCell>
                                            {rows[1]?.map((item, i) => (
                                                <StyledTableCell variant="footer" key={`${item}${i}`}>
                                                    <div dangerouslySetInnerHTML={{ __html: String(item) }}></div>
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    )}
                                </TableHead>
                                <TableBody>
                                    {rows.slice(currentTab === 2 ? 1 : 2).map((row, i) => (
                                        <StyledTableRow key={`${row}${i}`}>
                                            {currentTab !== 2 && (
                                                <StyledTableCell component="th" scope="row" isCheckbox>
                                                    <CustomizedCheckbox
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                            const newValue = event.target.checked;
                                                            if (newValue) {
                                                                setSelectedRows((prev) => [...prev, row as never]);
                                                            } else {
                                                                setSelectedRows((prev) => prev.filter((p) => JSON.stringify(p) !== JSON.stringify(row)));
                                                            }
                                                        }}
                                                        checked={selectedRows.some((r) => JSON.stringify(r) === JSON.stringify(row))}
                                                    />
                                                </StyledTableCell>
                                            )}
                                            {row.map((item, i) => (
                                                <StyledTableCell isCheckbox={currentTab !== 2} key={`${item}${i}`} component="th" scope="row">
                                                    {String(item)}
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </div>
        );
    };

    function removeElementAtIndex(arr: [], index: number) {
        if (index >= 0 && index < arr.length) {
            const newArr = arr.slice();
            newArr.splice(index, 1);
            return newArr;
        } else {
            return arr;
        }
    }

    const getTable = (tabIndex: number, fullHeight = false) => {
        if (tabIndex === 0) {
            // Hide material filed on table
            const mechanicalField = (rows.mechanical?.[0] as string[])?.findIndex((d: string) => d === 'Material')
            const mechanicalMap = rows?.mechanical?.map(row => removeElementAtIndex(row as [], mechanicalField))
            const compareData = selectedRows.filter((row1) => mechanicalMap.some((row2) => JSON.stringify(row1) == JSON.stringify(row2)));
            const compareDataWithHeader = [...mechanicalMap.slice(0, 2), ...compareData];
            if (mechanicalField !== -1) {
                return tableData(compare ? compareDataWithHeader : mechanicalMap, fullHeight, tabIndex)
            }

            return tableData(compare ? compareDataWithHeader : rows.mechanical, fullHeight, tabIndex);
        }
        if (tabIndex === 1) {
            const physicalField = (rows.physical?.[0] as string[])?.findIndex((d: string) => d === 'Material')
            const physicalMap = rows?.physical?.map(row => removeElementAtIndex(row as [], physicalField))
            const compareData = selectedRows.filter((row1) => physicalMap.some((row2) => JSON.stringify(row1) == JSON.stringify(row2)));
            const compareDataWithHeader = [...physicalMap.slice(0, 2), ...compareData];
            if (physicalField !== -1) {
                return tableData(compare ? compareDataWithHeader : physicalMap, fullHeight, tabIndex)
            }
            return tableData(compare ? compareDataWithHeader : rows.physical, fullHeight, tabIndex);
        }
        return rows.composition.map(c => tableData(c, fullHeight, tabIndex))
    };

    const getPrintTable = () => {
        if (!rows.mechanical && !rows.physical && !rows.composition) return;
        return (
            <div ref={tableRef}>
                {rows.mechanical?.[0]?.length > 0 && getTable(0, true)}
                {rows.physical?.[0]?.length > 0 && getTable(1, true)}
                {rows.composition?.[0]?.[0]?.length > 0 && getTable(2, true)}
            </div>
        );
    };

    function TabPanel(props: TabPanelProps) {
        const { value, index, ...other } = props;
        return (
            <div role="tabpanel" hidden={value !== index} {...other} key={index}>
                {value === index && getTable(index)}
            </div>
        );
    }
    const getTabs = useMemo(
        () => (
            <div className={styles.tableContainer}>
                <Box sx={{ width: "100%" }}>
                    <CustomizedTabs value={tab} onChange={handleChange} variant="fullWidth">
                        {rows.mechanical?.[0]?.length > 0 && <CustomizedTab value={0} label="Mechanical Properties" {...a11yProps(0)} />}
                        {rows.physical?.[0]?.length > 0 && <CustomizedTab value={1} label="Physical Properties" {...a11yProps(1)} />}
                        {rows.composition?.[0]?.[0]?.length > 0 && <CustomizedTab value={2} label="Composition" {...a11yProps(2)} />}
                    </CustomizedTabs>
                    <TabPanel value={tab} index={0} key={0} ></TabPanel>
                    <TabPanel value={tab} index={1} key={1} ></TabPanel>
                    <TabPanel value={tab} index={2} key={2}></TabPanel>
                </Box>
                <div className={styles.tableFooter}>
                    <p className={styles.note}>** For comparison only.</p>
                    <div className={styles.footerButtons}>
                        <Button
                            text={"Compare Results"}
                            propWidth="147px"
                            propHeight="27px"
                            showIcon={false}
                            propBorderColor={`var(--brand-primary-500)`}
                            propBackgroundColor={`var(--white)`}
                            propHoverBackgroundColor={`var(--brand-primary-500)`}
                            propTextColor="var(--brand-primary-500)"
                            propHoverText={`var(--white)`}
                            propFontSize="10px"
                            onClick={() => {
                                if (tab !== 2 && selectedRows?.length > 0) {
                                    setCompare(true);
                                }
                            }}
                        />
                        <div className={styles.buttonRight}>
                            <Button
                                text={"Print Results"}
                                propWidth="147px"
                                propHeight="27px"
                                showIcon={false}
                                propBorderColor={`var(--brand-primary-500)`}
                                propBackgroundColor={`var(--white)`}
                                propHoverBackgroundColor={`var(--brand-primary-500)`}
                                propTextColor="var(--brand-primary-500)"
                                propHoverText={`var(--white)`}
                                propFontSize="10px"
                                onClick={handleRenderPrintData}
                            />
                            {entries.cta && <Button
                                text={entries.cta.title}
                                propWidth="147px"
                                propHeight="27px"
                                showIcon={false}
                                propBorderColor={`var(--brand-primary-500)`}
                                propBackgroundColor={`var(--brand-primary-500)`}
                                propHoverBackgroundColor="transparent"
                                propTextColor="var(--white)"
                                propHoverText={`var(--brand-primary-500)`}
                                propFontSize="10px"
                                link={entries.cta.href}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        ),
        [tab, rows, selectedRows, compare]
    );

    const clearFilter = () => {
        setFilterTempValue(defaultFilter);
        setCompare(false);
        setSelectedRows([]);
        setFilter(defaultFilter);
    }

    return (
        <>
            <Helmet>
                {meta_title && <title>{meta_title}</title>}
                {meta_description && <meta name="description" content={meta_description} />}
                {keywords && <meta name="keywords" content={keywords} />}
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className={styles.container}>
                <PageTitle key="contactPageTitle" page_title={pageTitleData}></PageTitle>
                <div className={styles.main}>
                    <div className={styles.sidebarContainer}>
                        <div className={styles.switchWrapper}>
                            <p className={styles.switchTitle} style={switchChecked ? inactiveSwitchText : activeSwitchText}>
                                Imperial US/UK
                            </p>
                            <CustomizedSwitch
                                checked={measurementUnit === "METRIC"}
                                onChange={() => {
                                    clearFilter()
                                    setMeasurementUnit((prev) => (prev === "IMPERIAL" ? "METRIC" : "IMPERIAL"));
                                }}
                            />
                            <p className={styles.switchTitle} style={switchChecked ? activeSwitchText : inactiveSwitchText}>
                                Metric
                            </p>
                        </div>

                        <div className={styles.filterTitleWrapper}>
                            <p className={styles.filterTitle}>FILTERS</p>

                            <p
                                onClick={() => {
                                    clearFilter()
                                }}
                                className={styles.clearFilter}
                            >
                                Clear All Filters
                            </p>
                        </div>
                        <div className={styles.filterWrapper}>
                            {(rows.mechanical?.[0]?.find(m => m === 'Material') || rows.physical?.[0]?.find(p => p === 'Material')) && getSwiperRatio(ratiosDataMetals, "dieCastingMetal", "By Die Casting Metals", "Select all that apply.")}
                            {sliderData.map((slider) => slider)}
                        </div>
                    </div>
                    {getTabs}
                </div>
            </div>
            <div className={styles.displayNone}>{print && getPrintTable()}</div>
        </>
    );
}
import React, { useEffect, useState } from "react";
import styles from "../styles/ResuorceAside.module.css";
import { ResourcesRes } from "../typescript/pages";
import { getArticleList, getCaseStudyList } from "../helper";
import { getRandomItems } from "../helper/utils";

export default function ResourceAside({ type }: { resource_aside: ResourcesRes; type: string; }) {
    const [featuredResources, setFeaturedResources] = useState<ResourcesRes[]>([]);

    const getRelatedTitle = (resourceType: string): string => {
        switch (resourceType) {
            case 'Article':
                return 'Related Articles';
            case 'Case Study':
                return 'Related Case Studies';
            default:
                throw new Error(`Unsupported resource type: ${resourceType}`);
        }
    };

    const getRelatedLink = (resourceType: string): string => {
        switch (resourceType) {
            case 'Article':
                return 'Read the Article';
            case 'Case Study':
                return 'View the Case Study';
            default:
                throw new Error(`Unsupported resource type: ${resourceType}`);
        }
    };

    useEffect(() => {
        const fetchResources = async () => {
            try {
                let resources: any[]; // Adjust type based on the return type of the functions

                if (type === 'Article') {
                    resources = await getArticleList();
                } else if (type === 'Case Study') {
                    resources = await getCaseStudyList();
                } else {
                    resources = []; // Default case if type does not match
                }
                const flattenedResources = resources.flat(); // Flatten array of arrays
                const randomResources = getRandomItems(flattenedResources, 3); // Fetch random items
                setFeaturedResources(randomResources); // Update state
            } catch (error) {
                console.error("Error fetching resources:", error);
            }
        };

        fetchResources();
    }, []); // Empty dependencies array to run only on mount

  return (
    <div className={styles.container}>
      <div className={styles.resourcesAside}>
        {type && (
              <div className={styles.resourcesAsideTitle}>
                  {getRelatedTitle(type)}
              </div>
        )}
              {featuredResources?.length &&
                  featuredResources.map((article, index) => (
                <div key={index} className={styles.resourcesAsideSnippet}>

                          <div className={styles.resourcesAsideSnippetTitleContainer}>
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 36" fill="none">
                        <circle cx="18.7446" cy="18" r="18" fill="#BFBEBE" />
                                  </svg>
                              </div>
                    <div className={styles.resourcesAsideSnippetTitle}>
                        {article?.title}
                    </div>
              </div>
              <div className={styles.snippetContentContainer}>
                <div className={styles.snippetSubTitle}>
                  {article?.summary}
                </div>
                <a href={article.url || "/"}
                  className={styles.snippetCTA}
                >
                                  {getRelatedLink(type) }
                </a>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

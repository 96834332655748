import React, { useEffect, useState } from "react";
import styles from "../styles/BlogCard.module.css";
import { Img, ResourcesRes } from "../typescript/pages";
import { translate } from "../helper/translate";
import i18n from "../sdk/i18n";
export default function BlogCard({ blog_post, component_type }: {  blog_post: ResourcesRes;  component_type: string; }) {

    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    
    useEffect(() => {
        const handleLanguageChange = () => {
            setCurrentLanguage(i18n.language);
        };

        i18n.on("languageChanged", handleLanguageChange);

        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, []);

  const getBlogCardClass = (component_type: string) => {
    switch (component_type) {
      case "Resource Grid":
        return styles.resourceCarousel;
      case "Resource List":
        return styles.resourceList;
      case "Blog Grid":
        return styles.blogGrid;
      default:
        return styles.resourceCarousel;
    }
  };

  const splitDescription = (description: string) => {
    if (!description || description.length === 0) return "";

    const words = description.split(" ");
    const truncated = words
      .slice(0, 12)
      .join(" ")
      ?.replace(/<[^>]+>/g, "");

    // Add ellipses if the description has more than 10 words
    return words.length > 10 ? `${truncated}...` : truncated;
  };

  const getTitle = (blog_post_item: ResourcesRes) => {
    switch (component_type) {
        case "Resource Grid":
            if (
                (blog_post_item._content_type_uid === "page") &&
                blog_post_item.page_components?.[0]?.hero_banner?.banner_title
            ) {
                return blog_post_item.page_components[0].hero_banner.banner_title;
            }
            if (
                (blog_post_item._content_type_uid === "metal_selector_overview")
            ) {
                return blog_post_item.header_title;
            }
            return blog_post_item.title;
      case "Resource List":
      case "Blog Grid":
        if (
            (blog_post_item._content_type_uid === "page") &&
            blog_post_item.page_components?.[0]?.hero_banner?.banner_title
        ) {
            return blog_post_item.page_components[0].hero_banner.banner_title;
        }
        if (
            (blog_post_item._content_type_uid === "metal_selector_overview")
        ) {
            return blog_post_item.header_title;
        }
        switch (
          blog_post_item._content_type_uid ||
          blog_post_item.type?.toLowerCase()
        ) {
          case "webinar":
                return translate("webinar", currentLanguage);
            case "whitepaper":
                return translate("whitepaper", currentLanguage);
            case "article":
                return translate("article", currentLanguage);
          case "case_study":
            case "case study":
                return translate("case_study", currentLanguage);
            case "video":
                return translate("video", currentLanguage);
            case "news":
                return translate("news", currentLanguage);
            case "event":
                return translate("event", currentLanguage);
          default:
                return blog_post_item.title; // Fallback to the post's title
        }
      default:
        return blog_post_item.title;
    }
    };

    const PlayBtnSVG = () => {
        return (
            <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M52.0002 4.68018C25.8941 4.68018 4.68018 25.8941 4.68018 52.0002C4.68018 78.1063 25.8941 99.3202 52.0002 99.3202C78.1063 99.3202 99.3202 78.1063 99.3202 52.0002C99.3202 25.8941 78.1063 4.68018 52.0002 4.68018ZM70.5132 53.7671L43.2663 69.4732C42.9535 69.6804 42.5391 69.786 42.2263 69.786C41.9135 69.786 41.4991 69.6804 41.1863 69.4732C40.5606 69.0589 40.1463 68.4332 40.1463 67.7061V36.2949C40.1463 35.5677 40.5606 34.8406 41.1863 34.5278C41.8119 34.1134 42.6406 34.1134 43.2663 34.5278L70.5132 50.2339C71.1389 50.6482 71.5532 51.2738 71.5532 52.001C71.5532 52.7282 71.1348 53.4543 70.5132 53.7671Z" fill="white" />
            </svg>
        );
    };

  const getDescription = (blog_post_item: ResourcesRes) => {
      switch (component_type) {
        case "Resource Grid":
            if (
                blog_post_item._content_type_uid === "page" &&
                blog_post_item.description
            ) {
                return blog_post_item.description;
            }
            if (blog_post_item._content_type_uid === "metal_selector_overview" && blog_post_item.header_description) {
            return blog_post_item.header_description;
            }

            if (blog_post_item._content_type_uid === "metal_selector_overview" && blog_post_item.description) {
                return blog_post_item.description;
            }
            return splitDescription(blog_post_item.summary);
      case "Blog Grid":
          case "Resource List":
              if (
                  blog_post_item._content_type_uid === "page" &&
                  blog_post_item.description
              ) {
                  return blog_post_item.description;
              }
              if (blog_post_item._content_type_uid === "metal_selector_overview" && blog_post_item.header_description) {
                  return blog_post_item.header_description;
              }

              if (blog_post_item._content_type_uid === "metal_selector_overview" && blog_post_item.description) {
                  return blog_post_item.description;
              }
        return blog_post_item.title;
      default:
        return blog_post_item.title;
    }
  };

  const getLinkText = (blog_post_item: ResourcesRes) => {
    switch (
      blog_post_item._content_type_uid ||
      blog_post_item?.type?.toLowerCase()
    ) {
      case "webinar":
            return translate("view_webinar", currentLanguage ) + " >";
      case "whitepaper":
            return translate("view_whitepaper", currentLanguage) + " >";
      case "article":
            return translate("view_article", currentLanguage) + " >";
      case "case_study":
            return translate("view_case_study", currentLanguage) + " >";
      case "video":
            return translate("watch_video", currentLanguage) + " >";
      case "news":
            return translate("view_news", currentLanguage) + " >";
      case "event":
            return translate("view_event", currentLanguage) + " >";
      default:
            return translate("learn_more", currentLanguage) + " >";
    }
  };

    const getYouTubeThumbnail = (url: string): Img | null => {
        const regex =
            /(?:youtube\.com\/(?:.*[?&]v=|v\/|embed\/|shorts\/)|youtu\.be\/)([^"&?/ ]{11})/;
        const match = url.match(regex);

        return match
            ? {
                url: `https://img.youtube.com/vi/${match[1]}/hqdefault.jpg`,
                description: "YouTube Thumbnail",
                uid: match[1],
                title: "YouTube Video",
                filename: "",
                content_type: "Img"
            }
            : null;
    };

    console.log("featured");
    // Determine the featured image
    const featuredImage =
        blog_post.type === "Video" && blog_post.youtube
            ? getYouTubeThumbnail(blog_post.youtube) // Use YouTube thumbnail if it's a video
            : blog_post._content_type_uid === "page" &&
                blog_post.page_components &&
                blog_post.page_components.length > 0
                ? blog_post.page_components[0]?.hero_banner?.banner_media
                : blog_post.featured_image;

    return (

        <a href={blog_post.url} className={styles.linkWrapper}>
    <div className={`${styles.blogCard} ${getBlogCardClass(component_type)}`}>
                {featuredImage ? (
                    <div className={styles.imageContainer}>
            <img
              className={styles.imageIcon}
              loading="lazy"
              alt={featuredImage?.description || ""}
              src={
                  featuredImage?.url ? featuredImage.url + "?width=296&height=185&auto=webp&fit=crop" : ""
              }
            />
            {(blog_post.type === "Video") && (
                <div className={styles.playButtonWrapper}>
                    <PlayBtnSVG />
                </div>
            )}
            {component_type === "Resource Grid" && (
                <div className={styles.imageOverlay}></div>
            )}
            </div>
      ) : (
        //Needs to be rebuilt dynamically per brand.
        <img
          className={styles.imageIcon}
          loading="lazy"
          alt={"Default Resource Image"}
          src={
            "https://azure-na-images.contentstack.com/v3/assets/bltc3d459c709c6e7a5/bltbe37e282ea60ffda/671b192df9470596c8b73a1f/Image_(2).png"
          }
        />
      )}

      <div className={styles.content}>
        <div className={styles.copy}>
          <span className={`${styles.title} line-clamp-2`}>{getTitle(blog_post)}</span>
          <p className={`${styles.description} line-clamp-4`}>{getDescription(blog_post)}</p>
        </div>
        <span className={styles.link}>
          {getLinkText(blog_post)}
        </span>
              </div>

            </div>
        </a>
  );
}

import { Config, Region, LivePreview, Stack } from "contentstack";
import { Swiper as SwiperType } from "swiper/types";

const {
  REACT_APP_CONTENTSTACK_API_KEY,
  REACT_APP_CONTENTSTACK_DELIVERY_TOKEN,
  REACT_APP_CONTENTSTACK_ENVIRONMENT,
  REACT_APP_CONTENTSTACK_BRANCH,
  REACT_APP_CONTENTSTACK_REGION,
  REACT_APP_CONTENTSTACK_PREVIEW_TOKEN,
  REACT_APP_CONTENTSTACK_APP_HOST,
  REACT_APP_CONTENTSTACK_LIVE_PREVIEW,
  REACT_APP_CONTENTSTACK_PREVIEW_HOST,
  REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN,
  REACT_APP_CONTENTSTACK_BRAND,
} = process.env;

// Basic environment validation
export const isBasicConfigValid = () => {
  return (
    !!REACT_APP_CONTENTSTACK_API_KEY &&
    !!REACT_APP_CONTENTSTACK_DELIVERY_TOKEN &&
    !!REACT_APP_CONTENTSTACK_ENVIRONMENT &&
    !!REACT_APP_CONTENTSTACK_MANAGEMENT_TOKEN &&
    !!REACT_APP_CONTENTSTACK_BRAND
  );
};

// Live preview config validation
export const isLpConfigValid = () => {
  return (
    !!REACT_APP_CONTENTSTACK_LIVE_PREVIEW &&
    !!REACT_APP_CONTENTSTACK_PREVIEW_TOKEN &&
    !!REACT_APP_CONTENTSTACK_PREVIEW_HOST &&
    !!REACT_APP_CONTENTSTACK_APP_HOST
  );
};

// Set region
const setRegion = (): Region => {
  let region = "US" as keyof typeof Region;
  if (
    !!REACT_APP_CONTENTSTACK_REGION &&
    REACT_APP_CONTENTSTACK_REGION !== "us"
  ) {
    region = REACT_APP_CONTENTSTACK_REGION.toLocaleUpperCase().replace(
      "-",
      "_"
    ) as keyof typeof Region;
  }
  return Region[region];
};

// Set LivePreview config
const setLivePreviewConfig = (): LivePreview => {
  if (!isLpConfigValid())
    throw new Error(
      "Your LP config is set to true. Please make sure you have set all required LP config in .env"
    );
  return {
    preview_token: REACT_APP_CONTENTSTACK_PREVIEW_TOKEN as string,
    enable: REACT_APP_CONTENTSTACK_LIVE_PREVIEW === "true",
    host: REACT_APP_CONTENTSTACK_PREVIEW_HOST as string,
  } as LivePreview;
};

export const brandPrimary500Color = {
  dynacast: "#2273BA",
  signicast: "#D9272E",
  optimim: "#5B5297",
  techmire: "#2273BA",
  form_technologies: "#00A19B",
};

export type BrandName = keyof typeof brandPrimary500Color;

export const currentBrandPrimary500 = brandPrimary500Color[
  (process.env.REACT_APP_CONTENTSTACK_BRAND as BrandName) ?? 'dynacast'
];

// Contentstack SDK initialization (for content delivery)
export const initializeContentStackSdk = (): Stack => {
  if (!isBasicConfigValid())
    throw new Error("Please set your .env file before running the starter app");

  const stackConfig: Config = {
    api_key: REACT_APP_CONTENTSTACK_API_KEY as string,
    delivery_token: REACT_APP_CONTENTSTACK_DELIVERY_TOKEN as string,
    environment: REACT_APP_CONTENTSTACK_ENVIRONMENT as string,
    region: setRegion(),
    branch: REACT_APP_CONTENTSTACK_BRANCH || "main",
  };

  if (REACT_APP_CONTENTSTACK_LIVE_PREVIEW === "true") {
    stackConfig.live_preview = setLivePreviewConfig();
  }

  if (REACT_APP_CONTENTSTACK_BRAND) {
    if (REACT_APP_CONTENTSTACK_BRAND == "form_technologies") {
      document.documentElement.style.setProperty(
        "--brand-primary-500",
        "#00A19B"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-400",
        "#3B4AF"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-300",
        "#66C7C3"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-200",
        "#99D9D7"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-100",
        "#CCECEB"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-50",
        "#F2FAFA"
      );
    } else if (REACT_APP_CONTENTSTACK_BRAND == "dynacast") {
      document.documentElement.style.setProperty(
        "--brand-primary-500",
        "#2273BA"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-400",
        "#4E8FC8"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-300",
        "#7AABD6"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-200",
        "#A7C7E3"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-100",
        "#D3E3F1"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-50",
        "#F4F8FC"
      );
    } else if (REACT_APP_CONTENTSTACK_BRAND == "signicast") {
      document.documentElement.style.setProperty(
        "--brand-primary-500",
        "#D9272E"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-400",
        "#E15258"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-300",
        "#E87D82"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-200",
        "#F0A9AB"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-100",
        "#F7D4D5"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-50",
        "#FDF4F5"
      );
    } else if (REACT_APP_CONTENTSTACK_BRAND == "optimim") {
      document.documentElement.style.setProperty(
        "--brand-primary-500",
        "#5B5297"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-400",
        "#7C75AC"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-300",
        "#9D98C1"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-200",
        "#BDBAD5"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-100",
        "#DEDDEA"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-50",
        "#F7F6FA"
      );
    } else if (REACT_APP_CONTENTSTACK_BRAND == "techmire") {
      document.documentElement.style.setProperty(
        "--brand-primary-500",
        "#2273BA"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-400",
        "#4E8FC8"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-300",
        "#7AABD6"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-200",
        "#A7C7E3"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-100",
        "#D3E3F1"
      );
      document.documentElement.style.setProperty(
        "--brand-primary-50",
        "#F4F8FC"
      );
    }
  }

  return Stack(stackConfig);
};

// API host URL
export const customHostUrl = (baseUrl = ""): string => {
  return baseUrl.replace("api", "cdn");
};

// Generate prod API URLs based on region
export const generateUrlBasedOnRegion = (): string[] => {
  return Object.keys(Region).map((region) => {
    if (region === "US") {
      return `cdn.contentstack.io`;
    }
    return `${region}-cdn.contentstack.com`;
  });
};

// Prod URL validation for custom host
export const isValidCustomHostUrl = (url: string): boolean => {
  return url ? !generateUrlBasedOnRegion().includes(url) : false;
};

export const generateVideoThumbnail = (videoUrl: string): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const video = document.createElement("video");
    video.src = videoUrl;
    video.crossOrigin = "anonymous";

    video.addEventListener("loadeddata", () => {
      video.currentTime = 1; // Seek to a specific time
    });

    video.addEventListener("seeked", () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(video, 0, 0);
        resolve(canvas.toDataURL()); // Get the base64 image URL
      } else {
        reject(new Error("Unable to get canvas context"));
      }
    });

    video.addEventListener("error", () => {
      reject(new Error("Error loading video"));
    });
  });
};

export const generateWistiaThumbnail = async (
  wistia: string
): Promise<string> => {
  const jsonLink = extractWistiaJsonpUrl(wistia);
  if (!jsonLink) throw Error("Error getting wistia thumbnail");
  const response = await fetch(jsonLink);
  const text = await response.text();
  const jsonpData = text.match(/({.*})/);

  if (!jsonpData) throw Error("Error getting wistia thumbnail");

  const json = JSON.parse(jsonpData?.[0]);
  const asset =
    json?.media?.assets?.find((a: any) => a?.type === "original") ||
    json?.media?.assets?.[0];
    
  // preview middle of video
  const previewTime = Math.round((json?.media?.duration || 0) / 2)
  if (asset?.url) {
    const jpgUrl = asset.url.replace(".bin", ".jpg") + '?video_still_time=' + previewTime;
    return jpgUrl;
  }
  throw Error("Error getting wistia thumbnail");
};

export const getLocalizedUrl = (currentLocale: string, path: string) => {
  // Ensure path starts with "/"
  const normalizedPath = path.startsWith("/") ? path : `/${path}`;
  return `/${currentLocale}${normalizedPath}`;
};

export const extractWistiaIdFromHtmlSnippet = (htmlSnippet: string) => {
  const mediaMatch = htmlSnippet.match(/\/embed\/medias\/([a-zA-Z0-9]+)/);
  const iframeMatch = htmlSnippet.match(/\/embed\/iframe\/([a-zA-Z0-9]+)/);

  if (mediaMatch) {
    return mediaMatch[1];
  } else if (iframeMatch) {
    return iframeMatch[1];
  }

  return null;
};

export const extractWistiaJsonpUrl = (inputString: string) => {
  const scriptMatch = inputString.match(
    /https:\/\/fast\.wistia\.com\/embed\/medias\/\w+\.jsonp/
  );
  return scriptMatch ? scriptMatch[0] : null;
};

export const handleSwiperBreakpoint = (
  swiper: SwiperType,
  setSlidesPerView: React.Dispatch<React.SetStateAction<number>>
) => {
  if (swiper.params.slidesPerView === 'auto') {
    setSlidesPerView(swiper.slidesPerViewDynamic())
    return
  }
  setSlidesPerView(swiper.params.slidesPerView as number);
};

export const isValidLocale = (url: string) => {
  const pathSegments = url.split("/").filter(Boolean);
  return pathSegments[0];
}

export const setLocalStorageItem = (key: string, value: unknown) => {
  try {
      const serializedValue = JSON.stringify(value);
      localStorage.setItem(key, serializedValue);
  } catch (error) {
      console.error("Error setting local storage item:", error);
  }
};

export const getLocalStorageItem = (key: string) => {
  try {
      const serializedValue = localStorage.getItem(key);
      return serializedValue ? JSON.parse(serializedValue) : null;
  } catch (error) {
      console.error("Error getting local storage item:", error);
      return null;
  }
};

export const removeLocalStorageItem = (key: string) => {
  try {
      localStorage.removeItem(key);
  } catch (error) {
      console.error("Error removing local storage item:", error);
  }
};

export const scrollToTopSmooth = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
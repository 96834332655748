import React, { useState, CSSProperties, ReactElement } from "react";
import styles from "../styles/Button.module.css";

interface ButtonProps {
  text: string;
  link?: string; // Optional link, can be omitted for button functionality
  showIcon?: boolean;
  icon?: ReactElement;
  propHoverText?: string;
  propFlex?: string;
  propAlignSelf?: string;
  propWidth?: string;
  propHeight?: string;
  propPadding?: string;
  propDisplay?: string;
  propFontSize?: string;
  propMinWidth?: string;
  propHoverBackgroundColor?: string;
  propBackgroundColor?: string;
  propBorderColor?: string;
  propTextColor?: string;
    onClick?: () => void; // New optional onClick prop for button functionality
}

const Button: React.FC<ButtonProps> = ({
  text,
  showIcon,
  icon,
  propHoverText,
  propFlex,
  propAlignSelf,
  propWidth,
  propHeight,
  propPadding,
  propFontSize,
  propDisplay,
  propMinWidth,
  propHoverBackgroundColor,
  propBackgroundColor,
  propBorderColor,
  propTextColor,
  link,
    onClick, // Destructure the new onClick prop
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle: CSSProperties = {
    flex: propFlex,
    alignSelf: propAlignSelf,
    width: propWidth || "188px",
    height: propHeight || "48px",
    padding: propPadding,
    display: propDisplay,
    minWidth: propMinWidth,
    fontSize: propFontSize,
    backgroundColor: isHovered
      ? propHoverBackgroundColor || "var(--charcoal-500)"
      : propBackgroundColor || "transparent",
    color: isHovered
      ? propHoverText || "var(--brand-primary-500)"
      : propTextColor || "var(--brand-primary-500)",
    border: "1px solid var(--primary-500)",
    borderRadius: "var(--md)",
    boxSizing: "border-box",
    cursor: "pointer",
    transition:
      "background-color 300ms ease-out, color 300ms ease-out, box-shadow 300ms ease-out",
    textAlign: "center",
    textDecoration: "none",
    borderColor: propBorderColor || "var(--primary-500)",
  };

  const iconColor = isHovered
    ? propHoverText || "var(--brand-primary-500)"
    : propTextColor || "var(--brand-primary-500)";

  const commonProps = {
    className: styles.button,
    style: buttonStyle,
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
  };

  const handleClick = () => {
    setIsHovered(false);

    if (onClick) {
      onClick();
    }
  };

  return link ? (
    // Render as a link if link prop is provided
      <a {...commonProps}
          href={link}
          onClick={handleClick}
          target={new URL(link, window.location.origin).origin === window.location.origin ? '_self' : '_blank'}
          rel={new URL(link, window.location.origin).origin === window.location.origin ? '' : 'noopener noreferrer'} >
      {text}
      {showIcon && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="11"
          viewBox="0 0 7 11"
          fill="none"
          style={{ marginLeft: ".5rem" }}
        >
          <path
            d="M1 10.5L6 5.5L1 0.5"
            stroke={iconColor}
            style={{ transition: "stroke 300ms ease-out" }}
          />
        </svg>
      )}
    </a>
  ) : (
    // Render as a button if onClick is provided
    <button {...commonProps} onClick={handleClick} >
              {text}
      {showIcon && icon && <div>{icon}</div>}
      {showIcon && !icon && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="11"
          viewBox="0 0 7 11"
          fill="none"
          style={{ marginLeft: ".5rem" }}
        >
          <path
            d="M1 10.5L6 5.5L1 0.5"
            stroke={iconColor}
            style={{ transition: "stroke 300ms ease-out" }}
          />
        </svg>
      )}
    </button>
  );
};

export default Button;

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "../styles/AsideFilter.module.css";
import { AsideFilter as AsideFilterProp } from "../typescript/components";
import { TaxonomyRes } from "../typescript/pages";
import i18n from "../sdk/i18n";
import { translate } from "../helper/translate";
import SidebarModal from "./sidebar-modal";

type FilterProps = {
    label: string;
    uid: string;
    items: TaxonomyRes[];
    selectedItems: string[];
    setSelectedItems: Dispatch<SetStateAction<string[]>>;
    isExpanded: string | null;
    toggleExpand: (uid: string) => void;
};

const FilterCategory = ({
    uid,
    items,
    selectedItems,
    setSelectedItems,
    isExpanded,
    toggleExpand,
}: FilterProps) => (
    <div className={styles.taxonomySelect}>
        <div className={styles.filterHeaderWrapper}>
            <div className={styles.filterHeader}>{translate("by_type", i18n.language)}</div>
            <div className={styles.toggle} onClick={() => toggleExpand(uid)}>
                {isExpanded === uid ? (
                    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 4.00031H9" stroke="var(--brand-primary-500)" strokeLinecap="round" />
                    </svg>
                ) : (
                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 5.00024H9" stroke="var(--brand-primary-500)" strokeLinecap="round" />
                        <path d="M5 1.00024L5 9.00024" stroke="var(--brand-primary-500)" strokeLinecap="round" />
                    </svg>
                )}
            </div>
        </div>
        {isExpanded === uid && (
            <div className={styles.optionsWrapper}>
                {items.map(item => (
                    <label key={item.uid} className={styles.locationSelectOptions}>
                        <input
                            type="checkbox"
                            value={item.uid}
                            onChange={() =>
                                setSelectedItems(prevSelected =>
                                    prevSelected.includes(item.uid)
                                        ? prevSelected.filter(id => id !== item.uid)
                                        : [...prevSelected, item.uid]
                                )
                            }
                            checked={selectedItems.includes(item.uid)}
                        />
                        <span className={styles.selectBoxCheck}></span>
                        <span className={styles.country}>{item.name}</span>
                    </label>
                ))}
            </div>
        )}
    </div>
);

export default function AsideFilter({
    filter,
    onApplyFilters,
}: {
    filter: AsideFilterProp;
    onApplyFilters: (selectedFilters: any) => void;
}) {
    const [isExpanded, setIsExpanded] = useState<string | null>(null);

    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [selectedTechnologies, setSelectedTechnologies] = useState<string[]>([]);
    const [selectedServices, setSelectedServices] = useState<string[]>([]);
    const [selectedMetals, setSelectedMetals] = useState<string[]>([]);
    const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
    const [selectedFinishes, setSelectedFinishes] = useState<string[]>([]);

    const toggleExpand = (uid: string) => {
        setIsExpanded(isExpanded === uid ? null : uid);
    };

    useEffect(() => {
        const selectedFilters = {
            type: selectedTypes,
            technology: selectedTechnologies,
            service: selectedServices,
            metal: selectedMetals,
            industry: selectedIndustries,
            finishes: selectedFinishes,
        };
        onApplyFilters(selectedFilters);
    }, [selectedTypes, selectedTechnologies, selectedServices, selectedMetals, selectedIndustries, selectedFinishes]);

    const clearFilters = () => {
        setSelectedTypes([]);
        setSelectedTechnologies([]);
        setSelectedServices([]);
        setSelectedMetals([]);
        setSelectedIndustries([]);
        setSelectedFinishes([]);

        onApplyFilters({
            type: [],
            technology: [],
            services: [],
            metals: [],
            industries: [],
            finishes: [],
        });
    };

    return (
        <SidebarModal content={
            <div className={styles.aboutBanner}>
                <div className={styles.root}>
                    <section className={styles.locationsOptions}>
                        <div className={styles.categoryOptions}>
                            <div className={styles.category}>
                                <a className={styles.filters}>{translate("filters", i18n.language)}</a>
                            </div>
                            <div className={styles.clearAllFiltersButton}>
                                <a className={styles.clearAllFilters} onClick={clearFilters}>
                                    {translate("clear_all_filters", i18n.language)}
                                </a>
                            </div>
                        </div>
                        <div className={styles.filterOptions}>
                            {filter.types && (
                                <FilterCategory
                                    label="Types"
                                    uid="type"
                                    items={filter.types}
                                    selectedItems={selectedTypes}
                                    setSelectedItems={setSelectedTypes}
                                    isExpanded={isExpanded}
                                    toggleExpand={toggleExpand}
                                />
                            )}
                            {filter.technology && (
                                <FilterCategory
                                    label="Technology"
                                    uid="technology"
                                    items={filter.technology}
                                    selectedItems={selectedTechnologies}
                                    setSelectedItems={setSelectedTechnologies}
                                    isExpanded={isExpanded}
                                    toggleExpand={toggleExpand}
                                />
                            )}
                            {filter.industries && (
                                <FilterCategory
                                    label="Industry"
                                    uid="industry"
                                    items={filter.industries}
                                    selectedItems={selectedIndustries}
                                    setSelectedItems={setSelectedIndustries}
                                    isExpanded={isExpanded}
                                    toggleExpand={toggleExpand}
                                />
                            )}
                            {filter.finishes && (
                                <FilterCategory
                                    label="Finishes"
                                    uid="finishes"
                                    items={filter.finishes}
                                    selectedItems={selectedFinishes}
                                    setSelectedItems={setSelectedFinishes}
                                    isExpanded={isExpanded}
                                    toggleExpand={toggleExpand}
                                />
                            )}

                            {filter.services && (
                                <FilterCategory
                                    label="Service"
                                    uid="service"
                                    items={filter.services}
                                    selectedItems={selectedServices}
                                    setSelectedItems={setSelectedServices}
                                    isExpanded={isExpanded}
                                    toggleExpand={toggleExpand}
                                />
                            )}
                        </div>
                    </section>
                </div>
            </div>
        }></SidebarModal>
    );
}

import React, { useEffect, useState, useCallback } from "react";
import * as XLSX from "xlsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import styles from "../styles/ProductSpecs.module.css";
import parse from "html-react-parser";
import DOMPurify from 'dompurify';
import { ProductSpecs } from "../typescript/components";

interface TableRowData {
  label: string;
  standard1: string;
  standard2: string;
  optional1: string;
  optional2: string;
}

interface TableSection {
  section: string;
  rows: TableRowData[];
}

interface ProductSpecsTableProps {
  product_specs: ProductSpecs;
}

const parseExcelFile = async (fileUrl: string): Promise<TableSection[]> => {
  const response = await fetch(fileUrl);
  const data = await response.arrayBuffer();
  const workbook = XLSX.read(data, { type: "array" });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const rawData: (string | number)[][] = XLSX.utils.sheet_to_json(worksheet, {
    header: 1,
    defval: "",
  });

  while (
    rawData.length &&
    rawData[rawData.length - 1].every(
      (cell) => cell === "" || cell === undefined || cell === null
    )
  ) {
    rawData.pop();
  }

  const structuredData: TableSection[] = [];
  let currentSection: TableSection | null = null;
  rawData.forEach((row) => {
    const [section, label, standard1, standard2, optional1, optional2] =
      row.map((r) => r?.toString() || "");
    if (section) {
      currentSection = {
        section: section.toString(),
        rows: [{ label, standard1, standard2, optional1, optional2 }],
      };
      structuredData.push(currentSection);
    } else if (currentSection) {
      currentSection.rows.push({
        label,
        standard1,
        standard2,
        optional1,
        optional2,
      });
    }
  });

  return structuredData;
};

const ProductSpecsTable: React.FC<ProductSpecsTableProps> = ({
  product_specs,
}) => {
  const [tableData, setTableData] = useState<TableSection[]>([]);

  const fetchData = useCallback(async () => {
    if (product_specs?.specification_file?.url) {
      const structuredData = await parseExcelFile(
        product_specs.specification_file.url
      );
      setTableData(structuredData);
    }
  }, [product_specs?.specification_file?.url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

    return (
        <div className={styles.container}>
            {(product_specs?.content || product_specs?.specification_file?.url) && product_specs.title && (
                <h3 className={styles.overview}>{product_specs.title}</h3>
            )}
            {product_specs?.specification_file?.url && (
                <TableContainer>
                    <Table>
                        <TableHead>
                            {/* Determine if the first row's label is "Upgrade kit" */}
                            {tableData.length > 0 && tableData[0].rows.length > 0 && tableData[0].rows[0].label === "Available for Machine Model" ? (
                                // Default table structure
                                <TableRow style={{ backgroundColor: "var(--brand-primary-500)", color: "#fff" }}>
                                    <TableCell style={{ backgroundColor: "var(--brand-primary-500)", color: "#fff", fontSize: "18px" }}>Upgrade Kit</TableCell>
                                    <TableCell style={{ backgroundColor: "var(--brand-primary-500)", color: "#fff", fontSize: "18px" }}>Available for Machine Model</TableCell>
                                </TableRow>
                            ) : (
                                // Default table structure
                                <TableRow style={{ backgroundColor: "var(--brand-primary-500)", color: "#fff" }}>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                        <TableCell style={{ backgroundColor: "var(--brand-primary-500)", color: "#fff", fontSize: "18px" }} colSpan={2}>Standard</TableCell>
                                        <TableCell style={{ backgroundColor: "var(--brand-primary-500)", color: "#fff", fontSize: "18px" }} colSpan={2}>Optional</TableCell>
                                </TableRow>
                            )}
                        </TableHead>
                        <TableBody>
                            {tableData.map((section, sectionIndex) => {
                                const filteredRows =
                                    sectionIndex === 0 && section.rows.length > 0 && section.rows[0].label === "Available for Machine Model"
                                        ? section.rows.slice(1) // Skip first row
                                        : section.rows;
                                return (
                                    <React.Fragment key={sectionIndex}>
                                        {filteredRows.length > 0 && (
                                        <TableRow>
                                            <TableCell rowSpan={section.rows.length + 1} style={{ verticalAlign: "top", fontWeight: "bold" }}>
                                                {section.section}
                                            </TableCell>
                                            </TableRow>
                                        )}
                                        {filteredRows.length > 0 && (
                                        section.rows.map((row, rowIndex) => (
                                            (tableData[0].rows[0].label === "Available for Machine Model") ? (
                                                <TableRow key={rowIndex}>
                                                    <TableCell>{row.label}</TableCell>
                                                </TableRow>
                                            ) : (
                                                <TableRow key={rowIndex}>
                                                    <TableCell>{row.label}</TableCell>
                                                    <TableCell>{row.standard1}</TableCell>
                                                    <TableCell>{row.standard2}</TableCell>
                                                    <TableCell>{row.optional1}</TableCell>
                                                    <TableCell>{row.optional2}</TableCell>
                                                </TableRow>
                                            )
                                        ))
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {product_specs?.content && (
                <div className={styles.textWrapper}>
                    {parse(DOMPurify.sanitize(product_specs.content))}
                </div>
            )}
        </div>
    );



};

export default ProductSpecsTable;

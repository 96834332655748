import React, { useState, useEffect } from "react";
import styles from "../styles/ProductDisplayBanner.module.css";
import {
  ProductDisplayBanner as ProductDisplayBannerProp,
  ProductMedia,
} from "../typescript/components";
import Button from "./button";
import Modal from "./modal";
import { generateVideoThumbnail, generateWistiaThumbnail } from "../sdk/utils";
import Skeleton from "react-loading-skeleton";
import WistiaVideo from "./wistia-video";
import parse from "html-react-parser";
import DOMPurify from 'dompurify';

const PlayBtnSVG = () => {
  return (
      <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M52.0002 4.68018C25.8941 4.68018 4.68018 25.8941 4.68018 52.0002C4.68018 78.1063 25.8941 99.3202 52.0002 99.3202C78.1063 99.3202 99.3202 78.1063 99.3202 52.0002C99.3202 25.8941 78.1063 4.68018 52.0002 4.68018ZM70.5132 53.7671L43.2663 69.4732C42.9535 69.6804 42.5391 69.786 42.2263 69.786C41.9135 69.786 41.4991 69.6804 41.1863 69.4732C40.5606 69.0589 40.1463 68.4332 40.1463 67.7061V36.2949C40.1463 35.5677 40.5606 34.8406 41.1863 34.5278C41.8119 34.1134 42.6406 34.1134 43.2663 34.5278L70.5132 50.2339C71.1389 50.6482 71.5532 51.2738 71.5532 52.001C71.5532 52.7282 71.1348 53.4543 70.5132 53.7671Z" fill="white" />
      </svg>
  );
};

export default function ProductDisplayBanner({
  product_display_banner,
}: {
  product_display_banner: ProductDisplayBannerProp;
}) {
  const mediaAssets = product_display_banner.media || []; // Ensure mediaAssets is not null/undefined
  const [selectedMedia, setSelectedMedia] = useState<ProductMedia | null>(
    mediaAssets[0] || null
  );
  const [thumbnails, setThumbnails] = useState<{ [key: string]: string }>({});
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const closeModal = () => setIsModalOpen(false);

  // Generate thumbnails for video assets when component mounts
  useEffect(() => {
    const generateThumbnails = async () => {
      const newThumbnails: { [key: string]: string } = {};
      for (const media of mediaAssets) {
        try {
          if (media?.media?.content_type === "video/mp4") {
            newThumbnails[media.media.url] = await generateVideoThumbnail(
              media.media.url
            );
          } else if (media?.wistia) {
            newThumbnails[media.wistia] = await generateWistiaThumbnail(
              media?.wistia
            );
          }
        } catch (error) {
          console.error("Error generating thumbnail:", error);
        }
      }
      setThumbnails(newThumbnails);
      setSelectedMedia(mediaAssets[0]);
    };

    if (mediaAssets.length > 0) {
      generateThumbnails();
    }
  }, [mediaAssets]);

  const handleMediaClick = (media: ProductMedia) => {
    setSelectedMedia(media);
    setIsPlaying(false);
  };

  const handleVideoClick = () => {
    setIsPlaying(true);
    setIsModalOpen(true);
  };

  const isVideo = (media: ProductMedia | null) =>
    media?.media?.content_type === "video/mp4";
  const isWistia = (media: ProductMedia | null) => !!media?.wistia;

  const getModalContent = () => {
    if (!selectedMedia) return null;
    if (isVideo(selectedMedia)) {
      return (
        <video
          className={`${styles.mediaModal}`}
          controls
          autoPlay={isPlaying}
          aria-label="Selected video"
          onClick={(e) => e.stopPropagation()}
        >
          <source src={selectedMedia?.media?.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    if (isWistia(selectedMedia)) {
      return (
        <div className={`${styles.wistiaVideoModal}`}>
          <WistiaVideo embedUrl={selectedMedia.wistia} responsive={true} />
        </div>
      );
    }

    return (
      <img
        className={`${styles.mediaModal}`}
        loading="lazy"
        alt={selectedMedia?.media?.title || "Large media"}
        src={selectedMedia?.media?.url || "large-blank-card.png"}
        onClick={handleVideoClick}
      />
    );
  };

  const getPreviewMedia = () => {
    if (!selectedMedia) return <></>;
    if (isVideo(selectedMedia)) {
      return (
        <>
          <img
            className={styles.largeVideoIcon}
            src={
                thumbnails[selectedMedia?.media.url + "?width=138&auto=webp&fit=crop,smart"] ?? "/large-blank-card.png"
            }
            alt={selectedMedia?.media.filename ?? selectedMedia?.media.title}
          />
          <div className={styles.playButtonWrapper}>
            <PlayBtnSVG />
          </div>
        </>
      );
    }

    if (selectedMedia.wistia) {
      return (
        <>
          <img
            className={`${styles.largeVideoIcon}`}
            src={thumbnails[selectedMedia?.wistia] ?? "/large-blank-card.png"}
            alt="Wistia Preview"
          />
          <div className={styles.playButtonWrapper}>
            <PlayBtnSVG />
          </div>
        </>
      );
    }

    return (
      <img
        className={`${styles.largeVideoIcon}`}
        loading="lazy"
        alt={selectedMedia?.media?.title || "Large media"}
            src={selectedMedia?.media?.url + "?width=512&auto=webp&fit=crop,smart" || "large-blank-card.png"}
        onClick={handleVideoClick}
      />
    );
  };

  const getSmallCardImageSrc = (media: ProductMedia) => {
    if (isVideo(media)) {
        return thumbnails[media.media?.url + "?width=138&auto=webp&fit=crop,smart"] || "placeholder-image.png";
    }
    if (isWistia(media)) {
      return thumbnails[media.wistia] || "placeholder-image.png";
    }
      return media.media?.url + "?width=138&auto=webp&fit=crop,smart" || "placeholder-image.png"; // Fallback if media.url is undefined
  };

  return (
    <div className={styles.productDisplayHero}>
      <section className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.productFamily}>
            {product_display_banner.title}
          </h2>
          <div className={styles.divider} />
          <div className={styles.description}>
                      {parse(DOMPurify.sanitize(product_display_banner.description))}
          </div>
          <div className={styles.buttonGroup}>
            {product_display_banner.cta?.href &&
              product_display_banner.cta?.title && (
                <Button
                  key="ctaButton"
                  text={product_display_banner.cta.title}
                  showIcon={false}
                  propWidth="250px"
                  propBackgroundColor="var(--primary-500)"
                  propHoverBackgroundColor="transparent"
                  propTextColor="var(--white)"
                  propAlignSelf="stretch"
                  link={product_display_banner.cta.href}
                />
              )}
            {product_display_banner.download?.href &&
              product_display_banner.download?.title && (
                <Button
                  key="downloadButton"
                  text={product_display_banner.download.title}
                  showIcon={false}
                  propWidth="250px"
                  propTextColor="var(--primary-500)"
                  propBackgroundColor="transparent"
                  propHoverBackgroundColor="var(--primary-500)"
                  propHoverText="var(--white)"
                  propAlignSelf="stretch"
                  link={product_display_banner.download.href}
                />
              )}
          </div>
        </div>
        {mediaAssets.length > 0 && (
          <div className={styles.videoGrid}>
            {selectedMedia && (
              <div className={styles.videoWrapper} onClick={handleVideoClick}>
                {getPreviewMedia()}
                {isPlaying && isModalOpen && (
                  <Modal
                    className={styles.productMediaModal}
                    content={getModalContent() as JSX.Element}
                    closeHandler={closeModal}
                  />
                )}
              </div>
            )}
            <div
              className={`${styles.smallCards} ${
                mediaAssets?.length < 3 ? styles.leftCard : ""
              }`}
            >
              {mediaAssets.slice(0, 3).map((media, index) => (
                <div
                  key={index}
                  className={`
                    ${
                      index === 0
                        ? styles.smallVideoIconWrapper
                        : styles.blankCardIconWrapper
                    } ${
                    JSON.stringify(media) === JSON.stringify(selectedMedia) &&
                    styles.activeWrapper
                  }
                  `}
                  onClick={() => handleMediaClick(media)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      handleMediaClick(media);
                    }
                  }}
                >
                  {thumbnails[media?.media?.url] ||
                  media?.media?.url ||
                  thumbnails[media?.wistia] ||
                  media?.wistia ? (
                    <img
                      className={
                        index === 0
                          ? styles.smallVideoIcon
                          : styles.blankCardIcon
                      }
                      loading="lazy"
                      alt={`Thumbnail ${index}`}
                      src={getSmallCardImageSrc(media)}
                    />
                  ) : (
                    <Skeleton width={100} />
                  )}
                  {(isVideo(media) || isWistia(media)) && (
                    <div className={styles.playButtonWrapper}>
                      <PlayBtnSVG />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

import React, { useRef, useState, useEffect } from "react";
import styles from "../styles/Timeline.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Timeline as TimelineProp } from "../typescript/components";
import { Chevron } from "./icon/chevron";
import { handleSwiperBreakpoint } from "../sdk/utils";

export default function Timeline({ timeline }: { timeline: TimelineProp }) {
  const { eyebrow, title_alt, title, description, timelines } = timeline;
  const [data] = useState(
    timelines?.filter((tl) => tl.image?.url) || []
  );
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef<SwiperType | null>(null);
    const [currentSlidesPerView, setCurrentSlidesPerView] = useState<number>(7);

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.params) {
            const { navigation, pagination } = swiperRef.current.params;
            if (navigation) {
                navigation.prevEl = prevRef.current;
                navigation.nextEl = nextRef.current;
                swiperRef.current.navigation?.destroy();
                swiperRef.current.navigation?.init();
                swiperRef.current.navigation?.update();
            }
            if (pagination) {
                swiperRef.current.pagination?.destroy();
                swiperRef.current.pagination?.init();
                swiperRef.current.pagination?.update();
            }
        }
    }, [swiperRef.current]);

  return (
    <div className={styles.timelineContainer}>
      <div className={styles.headerWrapper}>
        {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
        {(title || title_alt) && (
          <div className={styles.titleWrapper}>
            <h3 className={styles.title}>
              {title} <span className={styles.titleAlt}>{title_alt}</span>
            </h3>
          </div>
        )}
        {description && <p className={styles.description}>{description}</p>}
      </div>
      {data.length > currentSlidesPerView && (
        <div className={styles.navigationContainer}>
          <div className={styles.navigation}>
            <div className={styles.buttonWrapper}>
              <button ref={prevRef} className={styles.prevButton}>
                <Chevron
                  direction="left"
                  color="var(--chevron-color, var(--charcoal-500))"
                  size={20}
                />
              </button>
            </div>
            <div className={styles.buttonWrapper}>
              <button ref={nextRef} className={styles.nextButton}>
                <Chevron
                  direction="right"
                  color="var(--chevron-color, var(--charcoal-500))"
                  size={20}
                />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className={`${styles.container} timeline`}>
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Virtual]}
          onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
          onBreakpoint={(swiper: SwiperType) =>
            handleSwiperBreakpoint(swiper, setCurrentSlidesPerView)
          }
          className="timeline"
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          pagination={{
            el: null,
            clickable: false,
            dynamicBullets: true,
          }}
          spaceBetween={0}
          breakpoints={{
            1200: {
              slidesPerView: 7,
            },
            800: {
              slidesPerView: 4.5,
            },
            0: {
              slidesPerView: 1.75,
            },
          }}
        >
          <div className={styles.yearWrapper}></div>
          <div>
            {data.length > 1 &&
              data.map((item, index) => {
                const isLocatedBelow = index % 2 === 1;
                return (
                  <SwiperSlide
                    key={`${item.description}-${index}`}
                    className={styles.slide}
                  >
                    <h5 className={styles.year}>{item.year}</h5>
                    <div
                      className={[
                        styles.wrapper,
                        isLocatedBelow ? styles.down : "",
                      ].join(" ")}
                    >
                      <div
                        className={
                          isLocatedBelow ? styles.longLine : styles.shortLine
                        }
                      ></div>
                      <img src={item.image.url} alt={item.image.description} />
                      <p
                        className={`${styles.description} line-clamp-4`}
                        title={item.description}
                      >
                        {item.description}
                      </p>
                    </div>
                  </SwiperSlide>
                );
              })}
          </div>
        </Swiper>
      </div>
    </div>
  );
}

import React, { useRef, useEffect, useState, useCallback } from "react";
import {  generateUniqueId, getRandomItems } from "../helper/utils";
import styles from "../styles/ResourceGrid.module.css";
import { ResourceGrid as ResourceGridProp } from "../typescript/components";
import CaseStudyGrid from "./case-study-grid";
import { getAllResources } from "../helper";
import BlogGrid from "./blog-grid";
import ResourceListGrid from "./resource-list-grid";
import i18n from "../sdk/i18n";

interface ResourceListProps {
  resource_grid: ResourceGridProp;
  taxonomies?: [{ taxonomy_uid: string; term_uid: string }];
}

export default function ResourceList({
  resource_grid,
  taxonomies,
}: ResourceListProps) {
  const [localResourceGrid, setLocalResourceGrid] = useState(resource_grid); // Local state for updated grid
    const isFetchingRef = useRef(false); // Track if fetch is in progress
    const [locale, setLocale] = useState(i18n.language); // Track current locale

    useEffect(() => {
        const handleLanguageChange = (lng: string) => {
            setLocale(lng); // Update the state to trigger a re-render
        };

        i18n.on("languageChanged", handleLanguageChange);

        return () => {
            i18n.off("languageChanged", handleLanguageChange);
        };
    }, []);



  const fetchResources = useCallback(async () => {
    if (
      !isFetchingRef.current &&
      (resource_grid.auto_resources ||
        !resource_grid.featured_resources ||
        resource_grid.featured_resources.length === 0)
    ) {
      isFetchingRef.current = true;
      try {
        const resources = await getAllResources(taxonomies);
        const flattenedResources = resources.flat();
        setLocalResourceGrid((prevGrid) => ({
          ...prevGrid,
          featured_resources: getRandomItems(flattenedResources, 3),
        }));
      } catch (error) {
        console.error("Error fetching resources:", error);
      } finally {
        isFetchingRef.current = false;
      }
    }
  }, [resource_grid.auto_resources, taxonomies]);

  useEffect(() => {
    fetchResources();
  }, [fetchResources]);

  const renderContentByComponentType = () => {
    switch (localResourceGrid.component_type) {
      case "Blog Grid":
        return (
          <BlogGrid
            key={`BlogGrid-${locale}`}
            resource_grid={localResourceGrid}
            taxonomies={taxonomies}
          />
        );
      case "Resource Grid":
        return (
            <ResourceListGrid
                key={`ResourceListGrid-${locale}`}
            resource_grid={localResourceGrid}
            taxonomies={taxonomies}
          />
        );
      case "Case Study Grid":
        return (
            <CaseStudyGrid
                key={`CaseStudyHero-${locale}`}
            resource_grid={localResourceGrid}
            taxonomies={taxonomies}
          />
        );
      default:
        return (
            <BlogGrid
                key={`BlogGrid-${locale}`}
            resource_grid={localResourceGrid}
            taxonomies={taxonomies}
          />
        );
    }
  };

  const isResourceOnlyContainCTA = () => {
    return (
      resource_grid?.featured_resources?.length === 0 &&
      !resource_grid?.title &&
      !resource_grid?.eyebrow &&
      !resource_grid?.description
    );
  };

  return (
    <div
      id={generateUniqueId(localResourceGrid.anchor || localResourceGrid.title)}
      className={`${styles.resourceGrid} ${
        isResourceOnlyContainCTA() ? styles.resourceGridMargin : ""
      }`}
    >
      {renderContentByComponentType()}
    </div>
  );
}
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getEventRes } from "../helper";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { EventRes } from "../typescript/pages";
import { EntryProps } from "../typescript/components";
import Presenters from "../components/presenters";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function Event({
  entry,
}: {
  entry: ({ page }: EntryProps) => void;
}) {
  const lpTs = useLivePreviewCtx();
  const { eventId } = useParams();
  const history = useNavigate();
  const { i18n } = useTranslation();
  const [getEntry, setEntry] = useState({
    event: {} as EventRes,
  });
  const [error, setError] = useState(false);

  async function fetchData() {
    try {
      const entryUrl = eventId ? `/resources/event/${eventId}` : "/";
      const event = await getEventRes(entryUrl);
      if (!event) setError(true);
      setEntry({ event });
      entry({ page: [event] });
    } catch (error) {
      console.error(error);
      setError(true);
    }
  }

  useEffect(() => {
    fetchData();
    if (error) history(`/${i18n.language}/404`);
  }, [eventId, lpTs, error, i18n.language]);

  const { event } = getEntry;

  const meta_title = event?.seo?.meta_title;
  const meta_description = event?.seo?.meta_description;
  const keywords = event?.seo?.keywords;

    const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
  return (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
              <meta name="robots" content="index, follow" />
              <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      {event?.presenters?.length > 0 && (
        <Presenters presenters={event.presenters} />
      )}
    </>
  );
}

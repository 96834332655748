import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getProductRes } from "../helper";
import Skeleton from "react-loading-skeleton";
import { useLivePreviewCtx } from "../context/live-preview-context-provider";
import { ProductRes } from "../typescript/pages";
import ProductDisplayBanner from "../components/product-display-banner";
import TabContainer from "../components/tab-container";
import { EntryProps } from "../typescript/components";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function Product({
  entry,
}: {
  entry: ({ page }: EntryProps) => void;
}) {
  const lpTs = useLivePreviewCtx();
    const { productId } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [getEntry, setEntry] = useState({
    product: {} as ProductRes,
  });
  const [error, setError] = useState(false);

    async function fetchData() {
        try {
            // Build the entry URL based on whether productFamily is present
            const entryUrl = location.pathname.startsWith(`/${i18n.language}`)
                ? location.pathname.replace(`/${i18n.language}`, "")
                : location.pathname;

            const product = await getProductRes(entryUrl);
            if (!product) {
                setError(true);
                return;
            }

            setEntry({ product });
            entry({ page: [product] });
        } catch (error) {
            console.error(error);
            setError(true);
        }
    }

  useEffect(() => {
    fetchData();
  }, [productId, lpTs, error, i18n.language]);

  useEffect(() => {
    if (error) navigate(`/${i18n.language}/404`);
  }, [error])

  const { product } = getEntry;
  if (!product) return <></>;
  const meta_title = product?.seo?.meta_title;
  const meta_description = product?.seo?.meta_description;
    const keywords = product?.seo?.keywords;
    const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
  return (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
              <meta name="robots" content="index, follow" />
              <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      {product.product_display_banner ? (
        <ProductDisplayBanner
          product_display_banner={product.product_display_banner}
        ></ProductDisplayBanner>
      ) : (
        <Skeleton height={400} />
      )}

      {product.tab_container ? (
        <TabContainer tab_container={product.tab_container}></TabContainer>
      ) : (
        <Skeleton height={200} />
      )}
    </>
  );
}

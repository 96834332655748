import React, { useState, useEffect } from "react";
import styles from "../styles/LocationOverview.module.css";
import { getLocationOverviewRes, getTaxonomyRes } from "../helper";
import { getLocationRes } from "../helper";
import {
  LocationsOverviewPage,
  TaxonomyRes,
  LocationRes,
} from "../typescript/pages";
import Filter from "../components/filters";
import {
  LocationsOverviewEntryProps,
  PageTitleHeader,
} from "../typescript/components";
import PageTitle from "../components/page-title";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function LocationsOverview({
  entry,
}: {
  entry: ({ locations_overview }: LocationsOverviewEntryProps) => void;
}) {
  const [getEntries, setEntries] = useState({} as LocationsOverviewPage);
  const [error, setError] = useState(false);
  const [dataFetched, setDataFetched] = useState(false); // Ensure data is only fetched once
  const [getLocations, setLocations] = useState<LocationRes[]>([]); // State to hold locations as an array
  const [getContinents, setContinents] = useState<TaxonomyRes[]>([]); // State to hold locations as an array
  const [getCountries, setCountries] = useState<TaxonomyRes[]>([]); // State to hold locations as an array
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    locationsFilter: [] as TaxonomyRes[],
    certificationFilter: [] as TaxonomyRes[],
  });

  const [filteredLocations, setFilteredLocations] = useState<LocationRes[]>([]); // Add state for filtered certifications

  // Function to fetch page data
  async function fetchData() {
    try {
      const [
        result,
        locations,
        locationFilter,
        certificationFilter
      ] = await Promise.all([
        getLocationOverviewRes("/locations"),
        getLocationRes(),
        getTaxonomyRes("location"),
        getTaxonomyRes("certifications"),
      ]);
      setFilters({
        locationsFilter: locationFilter || [],
        certificationFilter: certificationFilter || [],
      });

        setLocations(locations.flat() || []); // Ensure certifications is set as a flat array
      setFilteredLocations(locations.flat() || []); // Set the filtered list initially to match the full list
      setContinents(locationFilter.filter((loc) => loc.depth === 1));
      setCountries(locationFilter.filter((loc) => loc.depth === 2));

      if (!result) {
        setError(true);
      } else {
        setEntries({ ...result });
        entry({ locations_overview: [result] });
      }

      setDataFetched(true); // Mark that data has been fetched
    } catch (error) {
      setError(true);
      console.error(error);
    }
  }

  // useEffect to fetch data only once after mount
  useEffect(() => {
    if (!dataFetched) {
      fetchData();
    }
  }, [dataFetched]); // Only run useEffect once when data hasn't been fetched

  useEffect(() => {
    if (dataFetched) { // Only render when changing language after component mounted
      fetchData();
    }
  }, [i18n.language]);

  useEffect(() => {
    if (error) navigate(`/${i18n.language}/404`);
  }, [error])

  const filterOptions = {
    locations: filters.locationsFilter,
    certifications: filters.certificationFilter
  };

  const matchesSelectedFilters = (
    loc: LocationRes,
    selectedFilters: {
      locations: string[];
      certifications: string[];
      metals: string[];
    }
  ) => {
    return loc.taxonomies.some((taxonomy) => {
      if (
        selectedFilters.locations.includes(taxonomy.term_uid) &&
        taxonomy.taxonomy_uid === "location"
      ) {
        return true;
      }
      if (
        selectedFilters.certifications.includes(taxonomy.term_uid) &&
        taxonomy.taxonomy_uid === "certifications"
      ) {
        return true;
      }
      return false;
    });
  };

  // Function to filter certifications based on selected filters
  const applyFilters = (selectedFilters: {
    locations: string[];
    certifications: string[];
    metals: string[];
  }) => {
    if (
      selectedFilters.locations.length === 0 &&
      selectedFilters.certifications.length === 0
    ) {
      // No filters selected, show the entire list
      setFilteredLocations(getLocations);
    } else {
      // Filters are selected, apply the filtering logic
      const filtered = getLocations.filter((loc) => {
        return matchesSelectedFilters(loc, selectedFilters);
      });

      setFilteredLocations(filtered); // Update the filtered certifications list
    }
  };

  // Function to find the locations for a specific country
  const findLocationsForCountry = (countryUid: string) => {
    return filteredLocations.filter(
      (location) =>
        // Add safety checks for location.taxonomies before calling some()
        location.taxonomies &&
        location.taxonomies.some(
          (taxonomy) =>
            taxonomy.taxonomy_uid === "location" &&
            taxonomy.term_uid === countryUid
        )
    );
    };

  const pageTitleData = {
    title: getEntries?.header_title,
    description: getEntries?.header_description,
  } as PageTitleHeader;

  const meta_title = getEntries?.seo?.meta_title;
  const meta_description = getEntries?.seo?.meta_description;
    const keywords = getEntries?.seo?.keywords;
    const canonicalUrl = `${window.location.origin}${window.location.pathname}`;

  return (
    <>
      <Helmet>
        {meta_title && <title>{meta_title}</title>}
        {meta_description && (
          <meta name="description" content={meta_description} />
        )}
        {keywords && <meta name="keywords" content={keywords} />}
              <meta name="robots" content="index, follow" />
              <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <div className={styles.outerWrapper}>
        <PageTitle
          key="contactPageTitle"
          page_title={pageTitleData}
        ></PageTitle>
        <div className={styles.moduleWrapper}>
              <Filter
                key="locationsFilter"
                filter={filterOptions}
                onApplyFilters={applyFilters}
              />
          <div className={styles.frameParent}>
              {getEntries && getEntries.map_image && (
                <section className={styles.rectangleParent}>
                    <img
                      className={styles.imageIcon}
                      loading="lazy"
                      alt={getEntries.map_image.filename}
                      src={getEntries.map_image.url}
                    />
                </section>
              )}
                      <div className={styles.resultsWrapper}>
                          {getContinents
                              .filter((continent) => {
                                  // Check if the continent has any locations in its countries
                                  return getCountries.some(
                                      (country) =>
                                          country.parent_uid === continent.uid &&
                                          findLocationsForCountry(country.uid).length > 0
                                  );
                              })
                              .map((continent) => (
                                  <section key={continent.uid} className={styles.resultsGroup}>
                                      <div className={styles.continent}>{continent.name}</div>
                                      {getCountries
                                          .filter((country) => country.parent_uid === continent.uid)
                                          .map((country) => {
                                              const locationsForCountry = findLocationsForCountry(country.uid);
                                              if (locationsForCountry.length > 0) {
                                                  return (
                                                      <div key={country.uid}>
                                                          <div className={styles.country}>{country.name}</div>
                                                          {locationsForCountry.map((location) => (
                                                              <a
                                                                  key={location.uid}
                                                                  className={styles.location}
                                                                  href={location.url}
                                                              >
                                                                  {location.header_title} &gt;
                                                              </a>
                                                          ))}
                                                      </div>
                                                  );
                                              }
                                              return null;
                                          })}
                                  </section>
                              ))}
                      </div>

          </div>
        </div>
      </div>
    </>
  );
}
